.social-networkModeration{
  flex-wrap: wrap;
  gap: 1%;
  .rectangle-img-info{
    width: 24%;
    min-height: 100px;
  }
}
.containersSeconds{
  .Box{
    width: 49%;
    .targetContent-small{
      width: 100%;
      padding: 0;
      .marginTarget{
        width: 85%;
        padding-top: 2rem;
      }
    }
    .targetContent{
      width: 100%;
      .marginTarget{
        width: 85%;
      }
    }
  }
}

.section2{
  gap: 1%;
  .box{
    width: 33%;
    .rectangle-graph-meddium{
      width: 100%;
    }
    .targetContent-small{
      width: 100%;
    }
  }
}

.btnSerach1{
  background-color: #56ffbe !important;
  .MuiButton-label{
    color: #fff;
  }
}

.redSocial{
  gap: 1%;
  .rectangle-img-info{
    width: 32%;
    .number{
      margin-left: 15%;
    }
  }
}

.sectionSecond{
  gap: 1%;
  .box{
    width: 32%;
    .rectangle-graph-meddium{
      width: 100%;
    }
    .targetContent-small{
      width: 100% !important;
    }
  }
}

@media (max-width: 1024px){
  .contProfileList{
    .social-networkModeration{
      display: grid !important;
      grid-template-columns: 1fr 1fr;
      .rectangle-img-info{
        width: 100%;
      }
    }
    .sect3Boxes{
      flex-direction: column;
      .box{
        width: 100%;
        &:nth-child(1){
          .userByGender{
            width: 80%;
            margin: 0 auto;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
          }
        }
        &:nth-child(3){
          .targetContent-small{
            .marginTarget{
              width: 90%;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1024px){
  .contUserList{
    .redSocial{
      .rectangle-img-info{
        .number{
          margin-left: 60px !important;
        }
      }
    }
    .sectionSecond{
      flex-direction: column;
      .box{
        width: 100%;
        &:nth-child(1){
          .rectangle-graph-meddium {
            padding-top: 3rem;
            .userByGender{
              width: 80%;
              margin: 0 auto; 
            }
          }
        }
        .graph{
          display: flex;
          align-items: center;
          justify-content: center;
          .userByState{
            .chartData{
              .pieChart{
                margin-left: 0;
              }
            }
          }
        }
        &:nth-child(3){
          .marginTarget{
            width: 90%;
          }
        }
      }
    }
  }
}

@media (max-width: 640px){
  .TitleListTask{
    margin-top: -40px;
  }
  .TitleProfileList{
    margin-top: -10px !important;
  }
  .titleUserList{
    margin-top: -10px !important;
  }
  .contProfileList{
    .social-networkModeration{
      display: grid !important;
      grid-template-columns: 1fr 1fr;
      .rectangle-img-info{
        width: 100%;
        height: 130px;
        .number{
          left: 15px;
        }
        .text1{
          line-height: 1.2;
        }
        .text2{
          line-height: 1.2;
        }
      }
    }
    .sect3Boxes{
      .box{
        &:nth-child(2){
          .userByState{
            .chartData{
              .pieChart{
                margin-left: 5px;
              }
              .dataLegend{
                margin-left: 10px;
                .dataBox{
                  p{
                    font-size: 12px !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .contUserList{
    .redSocial {
      flex-direction: column;
      gap: 10px;
      .rectangle-img-info{
        width: 100%;
        .number{
          left: 15px;
        }
      }
    }
    .sectionSecond{
      flex-direction: column;
      .box{
        width: 100%;
        &:nth-child(1){
          .rectangle-graph-meddium{
            display: flex;
            align-items: center;
            padding-top: 0;
          }
        }
        &:nth-child(3){
          .targetContent-small{
            .marginTarget{
              width: 90%;
            }
          }
        }
        .userByState{
          margin-top: 2rem;
          .pieChart{
            width: 50%;
            margin-left: 5px;
          }
          .dataLegend{
            margin-left: 5px;
          }
        }
      }
    }
  }
}