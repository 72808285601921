.content-submenu-finance {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 20px 0;
}

.content-submenu-finance 
.selectorBox{
  margin-top: 10px;
  width: 180px;
}

.delivery-tail {
  width: 100px;
  position: relative;
  height: 30px;
  background: #FF642E 0% 0% no-repeat padding-box;
  border-radius: 3px;
  opacity: 1;
}

