.container-post{
  width: 100%;
  padding-left: 10px;
  height: 556px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 8px;     /* Tamaño del scroll en vertical */
    height: 8px;    /* Tamaño del scroll en horizontal */
  }
  &::-webkit-scrollbar-thumb {
    background: #fd7e14;
    border-radius: 4px;
    cursor: pointer;
  }
  &::-webkit-scrollbar-thumb:active {
    background-color: #999999;
  }
  .containerImgVideo{
    margin: 0 auto;
    img{
      object-fit: cover;
      margin: 0 auto;
      border-radius: 5px;
    }
    video{
      margin-left: calc(50% - 160px);
    }
  }
  .containerText{
    display: flex;
    gap: 4%;
    margin-top: 1.5rem;
    flex-direction: column;
    .insights1{
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      width: 98%;
      justify-content: center;
      align-items: flex-start;
      grid-auto-flow: column;
      p{
        margin: 0 !important; 
        display: flex;
        justify-content: center;
        div{
          position: relative;
          &:hover span{
            display: block;
          }
          .tipo{
            width: 70px;
            text-align: center;
          }
          p{
            font-size: 1.5rem;
            margin: 0 !important;
          }
        }
        span{
          font-weight: 500;
          color: #fff;
          padding: 2px 4px 2px 4px;
          font-size: 0.8rem;
          position: absolute;
          background-color: #05252D;
          left: 0;
          border-radius: 5px;
          top: -45px;
          display: none;
          width: auto;
          &::after{
            content: "";
            position: absolute;
            bottom: -9px;
            left: 18px;
            margin-left: -9px;
            width: 18px;
            height: 18px;
            background: #05252d;
            transform: rotate(45deg);
            z-index: -1;
          }
        }
        img{
          width: 30px;
          display: inline-block;
          cursor: pointer;
        }
      }
      a{
        margin: 0 !important;
        display: flex;
        justify-content: center;
        cursor: pointer;
        position: relative;
        &:hover .LinkPost{
          display: block;
        }
        .LinkPost{
          font-weight: 500;
          color: #fff;
          padding: 2px;
          font-size: 0.8rem;
          position: absolute;
          background-color: #05252D;
          right: 0;
          border-radius: 5px;
          top: -45px;
          width: 65px;
          text-align: center;
          display: none;
          &::after{
            content: "";
            position: absolute;
            bottom: -9px;
            right: 8px;
            margin-left: -9px;
            width: 18px;
            height: 18px;
            background: #05252d;
            transform: rotate(45deg);
            z-index: -1;
          }
        }
        img{
          width: 30px;
          
        }
      }
    }
    
  }
  .section2{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
    .Hashtags2{
      width: 48%;
      height: 125px;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 8px;     /* Tamaño del scroll en vertical */
        height: 8px;    /* Tamaño del scroll en horizontal */
      }
      &::-webkit-scrollbar-thumb {
        background: #fd7e14;
        border-radius: 4px;
        cursor: pointer;
      }
      &::-webkit-scrollbar-thumb:active {
        background-color: #999999;
      }
      h5{
        color: #939393;
        text-align: center;
      }
      span{
        height: 40px;
        
        p{
          margin: 0;
          border-bottom: 1px solid #CACACC;
          font-size: 1rem;
          font-weight: bold;
          color: #04adbf;
          span{
            color: #04adbf;
          }
          &:first-child span{
            color: #fff;
          }
        }
      }
    }
    .validationPost{
      width: 48%;
      h5{
        color: #939393;
        text-align: center;
        margin-bottom: 25px;
      }
      .ico{
        margin: 0 auto;
        width: 100px;
        display: flex;
        justify-content: center;
        margin-top: 10px;
        gap: 10px;
        .fa-check-circle{
          font-size: 2rem;
          text-align: center;
          color: #00ca72;
          cursor: pointer;
        }
        .fa-times-circle{
          font-size: 2rem;
          text-align: center;
          color: #FF3D57;
          cursor: pointer;
        }
      }
      
    }
  }
}

.ant-carousel .slick-prev{
  left: -10%;
}
.ant-carousel .slick-prev::before{
  content: '<';
  color: #04adbf;
  font-size: 5rem;
}
.ant-carousel .slick-next{
  right: -3%;
}
.ant-carousel .slick-next::before{
  content: '>';
  color: #04adbf;
  font-size: 5rem;
}
.ant-carousel .slick-dots-bottom{
  display: none !important;
}


.sinPosts{
  height: 556px;
  width: 100%;
  img{
    width: 90%;
    opacity: 0.6;
    margin: 0 auto;
    margin-top: calc(50% - 86px);
  }
}

.btn12{
  border: none;
  background-color: transparent;
}