
.form{
  height: calc(100vh - 60px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-margin{
  width: 70%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.subTitle{
  color: #868688;
  font-weight: bold;
  margin: 0;
}

.title{
  color: #292f4c;
  margin: 0;
}

.btn-plus{
  padding: 2px 14px;
  text-decoration: none;
  color: white;
  background: orangered;
  box-shadow: 0 0 10px orangered;
  border-radius: 10px;
  font-weight: bold;
  font-size: 50px;
}

@media only screen and (max-width: 320) {
  /* menú lateral */
  .settings-nav {
    width: 40px;
  }
  .form-margin {
    width: 50%;
    margin-left: 10px;
  }
  .text-home h1 {
    font-size: 1.8rem;
  }
  .text-container p {
    text-align: center;
  }
}