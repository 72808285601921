.btnOrangePage-dash {
    width: 20px;
    height: 20px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

.firstmodal i {
  padding: 4px;
}

.firstmodal .btnSysPlus {
   border-radius: 4px;
   background-color: #ff9015;
   color:#fff;
   cursor: pointer;
   padding: 5px;
   margin: 0px 4px  0px 0px;
}

.firstmodal .btnSysEye {
  border-radius: 4px;
  background-color: #86ddd8;
  color:#fff;
  cursor: pointer;
  padding: 5px;
  margin: 0px 4px  0px 0px;
}

.firstmodal .fa-times-circle {
 cursor: pointer !important;
}