body {
  background: #F7F7F7;
}

.settings-nav {
  left: 0;
  display: flex;
  position: fixed;
  height: 100% !important;
  top: 0;
  width: 70px;
  padding: 50px 0 0 !important;
  max-width: unset;
  z-index: 9;
  transition: all 0.5s ease;
  box-shadow: 0px 0px 00px #00000029;
}



.settings-nav.col-md-3 {
  width: 320px;
  box-shadow: 10px 0px 20px #00000029;
  z-index: 9999;
}



.settings-nav-itemClose {
  border-left: 4px solid #0000;
  font-size: 1em;
}



.settings-nav-itemClose:hover {
  border-left: 4px solid #fd9015;
  background-color: #f2ddc6;
}



.form-dashboard {
  max-width: unset;
  margin-right: 0;
  margin-left: 0;
  max-width: 1240px;
  width: 87%;
}



.settings-nav-item:hover>a {
  color: #FF9015!important;
  background: #f2ddc6;
  padding: 5px 0 5px 10%;
  width: 95%;
}



.btnOrange-dash {
  position: relative;
  z-index: 6;
  background: #FF9015;
  padding: 1px 6.5px;
  color: #fff!important;
  font-size: 28px;
  height: 41px;
}



.content-section, .row-info {
  display: -webkit-flex;
  display: flex;
}



.rectangle {
  width: 24%;
}



.targetContent-small .marginTarget .dataBox, .targetContent-small .marginTarget .dataBox, .targetContent .marginTarget .dataBox {
  height: 45px;
  opacity: 1;
  border-bottom: 1px solid #ccc;
  line-height: 45px;
}



.targetContent-small .marginTarget .dataBox:last-child, .targetContent-small .marginTarget .dataBox:last-child, .targetContent .marginTarget .dataBox:last-child {
  border-bottom: 0px solid !important;
}



header.header {
  width: 100%;
  position: fixed;
  z-index: 99999;
}



.content-all.accounts-page {
  padding: 60px 0 0;
}



@media (min-width: 768px) {
  .navbar-expand-md .navbar-collapse {
      margin: 7px 0 0;
  }
}



a#colum-btn {
  height: 41px;
}



a#filter-btn {
  height: 41px;
}



.firstColl.w-100 {
  z-index: 1;
}



p.name-fc {
  z-index: 1;
}



.icon-container {
  z-index: 999;
  width: 140px;
  padding: 10px 0 10px 25px;
  left: -125px;
  border-radius: 5px;
  transition: all 0.5s ease;
}



.icon-container:hover {
  transition: .3s;
  border-radius: 0 5px 5px 0;
  left: -15px;
}



a.settings-nav-link i {
  font-weight: 700;
  font-size: 16px;
  line-height: 0.6;
}



a.settings-nav-link {
  font-weight: 400;
  font-size: 15px
}



.settings-nav-item ul li a {
  font-size: 0.9em;
  margin: 5px 0 0 66px;
}



.settings-nav-item:hover ul {
  height: 150px;
}



.w-100.titleOneStep {
  width: 96% !important;
  border-color: #9a9a9a;
}



.ml-auto.nav-options.navbar-nav li:last-child a {
  font-size: 0.4em !important;
  margin: 4px 0 0 0;
}

.MuiAutocomplete-inputRoot .MuiAutocomplete-input {
  box-shadow: none;
}

.blur-div {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(8px);
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-modal {
  background: #EFF0F2;
  width: 85%;
  height: 90%;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 7px 14px #00000040;
}

.closeModal-content {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  color: #CCCCCC;
}

.closeModal-content i {
  font-size: 25px;
  margin: 10px 10px 0;
  cursor: pointer;
}



.titleModal {
  font-size: 23px;
    margin: 0 3%;
    background-color: #EFF0F2;
}
/********************************/

.moderation-modal {
  background:#EFF0F2;
  width: 70%;
  height: 70%;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 7px 14px #00000040;
}


/********************************/
.body-modal {
  height: calc(100% - 136px);
  display: flex;
  justify-content: space-evenly;
  overflow-y: scroll;
  background-color: #EFF0F2;
  padding-top: 20px;
}

.part-BodyModal {
  width: 43%;
}

.part-BodyModal p{
  margin: 0;
  font-weight: bold;
}

.buttons-Modal-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.footer-Modal {
  display: flex;
  background: #EFF0F2;
  padding: 20px 4%;
}


.buttons-Modal-content button{
  border: none;
    padding: 5px 5%;
    box-shadow: 5px 5px 15px #00000029;
    border-radius: 5px;
    color: white;
    font-weight: bold;
}

.buttons-Modal-content button:nth-child(1){
  background: #7E7E80;
}

.buttons-Modal-content button:nth-child(2){
  background: #FF9015;
}

.w-90{
  width: 90%;
}

.mb-15{
  margin-bottom: 15px;
}

.MuiInput-root {
  width: 100%;
}

.MuiInput-underline:after {
  border-bottom: 2px solid #FF9015 !important;
}

.tagContent {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 5px 0;
}


.buttons-Moderation-Modal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}


.buttons-Moderation-Modal button{
  border: none;
   
    box-shadow: 5px 5px 15px #00000029;
    border-radius: 5px;
    color: white;
    font-weight: bold;
}

.buttons-Moderation-Modal button:nth-child(1){
  background: #FF3D57;
}

.buttons-Moderation-Modal button:nth-child(2){
  background: #00CA72;
}


.hashtag{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  color: white !important;
  margin: 0 10px 5px 0;
}

.hashtag p{
  margin-right: 10px;
}

.hashtag i{
  cursor: pointer;
}

.dialog-date-picker {
  filter: hue-rotate(162deg);
}

.imageLoaderContent{
  width: 20%;
  margin-right: 10px;
  position: relative;
}

.imageLoader{
  width: 100%;
}

.deleteImageLoader{
  position: absolute;
  top: -5px;
  right: -5px;
  font-size: 20px;
  color: #dc3545;
  background: white;
  border-radius: 50px;
  cursor: pointer;
}

.color{
  background: #ff3d57;
  border-radius: 25px;
  color: #ff3d57;
 }
  
 .color:nth-child(2){
  background: #ff642e;
  border-radius: 25px;
  color: #ff642e;
 }
  
 .color:nth-child(3){
  background: #fdab3d;
  border-radius: 25px;
  color: #fdab3d;
 }
  
 .color:nth-child(4){
  background: #00ca72;
  border-radius: 25px;
  color: #00ca72;
 }
  
 .color:nth-child(5){
  background: #009aff;
  border-radius: 25px;
  color: #009aff;
 }
  
 .color:nth-child(6){
  background: #794fcf;
  border-radius: 25px;
  color: #794fcf;
 }
 .color:nth-child(7){
  background: #cacacc;
  border-radius: 25px;
  color: #cacacc;
 }
  
 .color:nth-child(8){
  background: #292f4c;
  border-radius: 25px;
  color: #292f4c;
 }

 .MuiFormControl-marginNormal {
   margin-top: 0 !important;
 }

@media (max-width: 800px) {
 .body-modal{
  flex-wrap: wrap;
 }
 .part-BodyModal{
  width: 92%;
 }
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: space-between;
}

.column-grid{
  width: 371px;
}

.title-grid::before{
  content: "";
  display: block;
  background: #7ADBD4 0% 0% no-repeat padding-box;
  opacity: 1;
  margin-left: -30px;
  position: absolute;
  border-radius: 50%;
  width: 20px;
  height: 20px;
}

.title-grid{
  margin-left: 30px;
  margin-top: 25px;
  margin-bottom: 25px;
  font: normal normal bold 16px/12px;
}

.nav-link i{
  color:#05252D;
}

.content-row{
  justify-content: space-between;
  display: flex;
}

.body-container{
  width: 100%;
}

.content-section{
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.title-campaign{
  background: #7ADBD4 0% 0% no-repeat padding-box;
    box-shadow: 5px 5px 10px #0000001f;
    border-radius: 5px;
    opacity: 1;
    min-width: 361px;
    height: 40px;
    display: flex;
    align-items: center;
    margin-top: 10px;
    color: #FFFFFF;
  }

  .title-campaign i{
    margin-left: 10px;
  }
  .title-campaign i:hover{
    margin-left: 10px;
    color: #646464;
  }
 
  .title-campaign a{
    font: normal normal bold 18px/18px ;
    text-align: center;
    margin-left: 15px;
  }

  .image-gallery{
    width: 742px;
  }

  .acceptButton{
    background: #008000 0% 0% no-repeat padding-box;
      box-shadow: 5px 5px 10px #0000001f;
      border-radius: 5px;
      opacity: 1;
      min-width: 200px;
      height: 40px;
      display: flex;
      align-items: center;
      margin-top: 10px;
      color: #FFFFFF;
      margin-left: 20px;
      border: none;
    }

    .acceptButton a{
      font: normal normal bold 18px/18px ;
      text-align: center;
      margin-left: 15px;
    }

    .acceptButton i{
      margin-left: 10px;
    }

    .rejectedButton{
      background: #a2231d 0% 0% no-repeat padding-box;
        box-shadow: 5px 5px 10px #0000001f;
        border-radius: 5px;
        opacity: 1;
        min-width: 200px;
        height: 40px;
        display: flex;
        align-items: center;
        margin-top: 10px;
        color: #FFFFFF;
        border: none;
      }
  
      .rejectedButton a{
        font: normal normal bold 18px/18px ;
        text-align: center;
        margin-left: 15px;
      }
  
      .rejectedButton i{
        margin-left: 10px;
      }