.rectangle{	
  width: 266px;
  height: 100px;
  display: block;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 5px 5px 10px #0000001F;
  border-radius: 5px;
  opacity: 1;
}

.rectangle .title{
  margin-top: 10px;
  margin-left: 10px;
font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-12)/var(--unnamed-line-spacing-12) var(--unnamed-font-family-poppins);
letter-spacing: var(--unnamed-character-spacing-0-3);
text-align: left;
font: normal normal bold 12px/12px PierSans;
letter-spacing: 0.3px;
color: #05252D;
opacity: 1;
}

.rectangle .number{
  margin-top:20px;
  margin-left:10px;
font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-24)/var(--unnamed-line-spacing-12) var(--unnamed-font-family-poppins);
letter-spacing: var(--unnamed-character-spacing-0-6);
text-align: left;
font: normal normal bold 24px/12px PierSans;
letter-spacing: 0.6px;
color: #05252D;
opacity: 1;
}

.rectangle .description{
  margin-left: 10px;
  margin-top: 10px;
font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-12)/var(--unnamed-line-spacing-12) var(--unnamed-font-family-poppins);
letter-spacing: var(--unnamed-character-spacing-0-3);
text-align: left;
font: normal normal normal 12px/12px PierSans;
letter-spacing: 0.3px;
color: #04ADBF;
opacity: 1;
}
