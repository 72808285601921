.delivery-running{
    width: 100px;
    position: relative;
  height: 30px;
  background: #00CA72 0% 0% no-repeat padding-box;
  border-radius: 3px;
  opacity: 1;
  }
  
  .delivery-tail{
    width: 100px;
    position: relative;
  height: 30px;
  background: #FF642E 0% 0% no-repeat padding-box;
  border-radius: 3px;
  opacity: 1;
  }
  
  .delivery-stop{
    width: 100px;
    position: relative;
  height: 30px;
  background: #FF3D57 0% 0% no-repeat padding-box;
  border-radius: 3px;
  opacity: 1;
  }
  
  .delivery-complete{
    width: 100px;
    position: relative;
  height: 30px;
  background: #3d4aff 0% 0% no-repeat padding-box;
  border-radius: 3px;
  opacity: 1;
  }
  .delivery-payment{
    width: 100px;
    position: relative;
  height: 30px;
  background: #c0c0c0 0% 0% no-repeat padding-box;
  border-radius: 3px;
  opacity: 1;
  }
  .delivery-text{
    position:absolute;
    margin-top: 10px;
    margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 10px/var(--unnamed-line-spacing-12) var(--unnamed-font-family-poppins);
  text-align: center;
  font: normal normal normal 10px/12px PierSans;
  letter-spacing: 0.25px;
  color: #FFFFFF;
  }
  
.BtnPoli{
  width: 240px;
  height: 40px;
  background-color: #57d4fd !important;
  color: #fff;
  margin: 0 auto 1rem;
}

.titleModal{
  margin-top: 2rem;
}
h5{
  font-weight: bold;
}
h6{
  font-weight: bold;
  margin-left: 10px;
}
p{
  font-size: 1rem !important;
}
.bodyModal{
  flex-direction: column;
  padding: 0 40px;
  justify-content: flex-start;
  margin-top: 20px;
  height: 500px;
}

@media (max-width: 1400px){
  .bodyModal{
    height: 300px;
  }
}

.contPDF{
  height: 100vh;
}

.modalPDF{
  height: 90% !important;
}