.rectangle-img{
  width: 266px;
  height: 100px;
  position: relative;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 5px 5px 10px #0000001F;
  border-radius: 5px;
  opacity: 1;
}

.rectangle-img-info{
  width: 266px;
  height: 100px;
  position: relative;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 5px 5px 10px #0000001F;
  border-radius: 5px;
  opacity: 1;
}

.rectangle-img-info-small .text{
  margin-left: 20px;
  margin-top: 7px;
  text-align: left;
font: normal normal normal 11px/12px ;
letter-spacing: 0.3px;
color: #05252D;
line-height:1;
}

.rectangle-img-info-small{
  height: 33px;
  display: flex;
  margin-bottom: 10px;
}

.rectangle-img-info-small .image{
  width: 30px;
height: 30px;
}

.rectangle-img .image{
  margin-left: 15px;
  position: absolute;
  margin-top:25px;
}

.rectangle-img-info-small
p {
  font-size: 0.7rem !important;
}
.rectangle-img-info-small
.text1 {
  margin-bottom: 5px;
}

.rectangle-img-info-small
.text2 {
  margin-top: 5px;
}
.rectangle-img-info .image{
  width: 30px;
  height: 30px;
  margin-left: 15px;
  position: absolute;
  margin-top:20px;
}

.rectangle-img-info .text{
  position: absolute;
  margin-top: 65px;
  margin-left: 15px;
}
.rectangle-img-info .text p{
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-12)/16px var(--unnamed-font-family-poppins);
letter-spacing: var(--unnamed-character-spacing-0-3);
text-align: left;
font: normal normal normal 12px/16px ;
letter-spacing: 0.3px;
color: #05252D;
opacity: 1;
line-height: 5px;   /* within paragraph */
  margin-bottom: 10px; /* between paragraphs */
}

.rectangle-img-info .number{
  position: absolute;
  width: 25px;
height: 33px;
  margin-top: 30px;
  margin-left: 28%;
font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-24)/var(--unnamed-line-spacing-12) var(--unnamed-font-family-poppins);
letter-spacing: var(--unnamed-character-spacing-0-6);
text-align: left;
font: normal normal bold 24px/12px ;
letter-spacing: 0.6px;
color: #05252D;
opacity: 1;
}

.rectangle-img .number{
  position: absolute;
  margin-top: 38px;
  margin-left: 28%;
font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-24)/var(--unnamed-line-spacing-12) var(--unnamed-font-family-poppins);
letter-spacing: var(--unnamed-character-spacing-0-6);
text-align: left;
font: normal normal bold 24px/12px ;
letter-spacing: 0.6px;
color: #05252D;
opacity: 1;
}

.rectangle-img .description{
height: 17px;
font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-12)/var(--unnamed-line-spacing-12) var(--unnamed-font-family-poppins);
letter-spacing: var(--unnamed-character-spacing-0-3);
text-align: left;
font: normal normal normal 12px/12px ;
letter-spacing: 0.3px;
color: #04ADBF;
opacity: 1;
}
