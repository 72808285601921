
a:hover{
  color: white;
  text-decoration: none;
}
.divPais {
  /* margin-top: -274px;
  position: fixed; */
  width: 100%;
  margin-left: 25px;
  margin-right: -87px;
}
.divPais span{
  padding-left: 231px !important;
}
.phoneNumber .inputSelectComplete{
  width: 25% !important
}
.phoneNumber .inputNumberComplete{
  width: 75%;
}
.phoneNumber{
  width: 68%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.boxInput{
  display: flex;
  width: 80%;
  
}
.register {
  width: 68%;
}
.register2 {
  margin-left: 21%;
  margin-right: 53%;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.register2 .regresar{
  margin-left: 67%;
}
.formInputNumber{
  /* width: 20%;
  border-radius: 5px;
  border: none;
  box-shadow: 3px 3px 5px #80808080;
  margin: 3px 0 10px 0;
  padding: 10px; */
}
.formInputNumberText{
  /* width: 20%;
  border-radius: 5px;
  border: none;
  box-shadow: 3px 3px 5px #80808080;
  margin: 3px 0 10px 0;
  padding: 10px; */
}

.form-login{
  height: calc(100vh - 60px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.form-control {

  width: 80% !important;
}
.invalid-feedback {

  width: 80% !important;
}
.form-margin-login{
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 10%;
}

.lado{
  
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
}

.title-form{
  color: #292f4c;
  margin: 0;
  font-weight: bold;
  margin-bottom: 30px;
}

.subTitle-form{
  width: 100%;
  text-align: center;
  color: #292f4c;
  margin: 0;
  font-weight: bold;
  margin-top: 40px;
  margin-bottom: 20px;
}

.textos{
  color: #292f4c;
  margin: 0;
  font-weight: bold;
  width: 80%;
  padding-left: 10px;
  font-size: 14px;
  margin-top: 6px;
}

.passForget{
  width: 77%;
  text-align: right; 
   color: #57D4FD; 
   margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}

.passForget a:hover{
  color: #57D4FD;
}
.passForgetA{
  margin-left: 13px;
}


.link a{
  color: #57D4FD;
}

.link a:hover{
  color: #57D4FD;
}

.formInput{
  width: 80%;
  border-radius: 5px;
  border: none;
  box-shadow: 3px 3px 5px #80808080;
  margin: 3px 0 10px 0;
  padding: 10px;
}

.btn-plus-form{
  text-decoration: none;
  color: white !important;
  background: #5CF993 !important;
  box-shadow: 1px 1px 5px #80808091;
  border-radius: 5px;
  font-weight: bold;
  font-size: 20px;
  width: 100%;
  text-align: center;
  margin: 10px 30% 0 30%;
  padding: 5px;
}

.redes{
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
}

.redes a{
  flex-grow: 1;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 4px;
}

.facebook{
  background: #1877f2;
  color: white;
  font-weight: bold;
  padding: 5px;
  border-radius: 5px;
}


.google{
  padding: 10px;
  color: grey;
  font-weight: 500;
  border-radius: 3px;
  box-shadow: 0px 2px 3px #dbdbdb;
  margin-top: 10px;
}

.google img {
  width: 25px;
}

.google:hover {
  color: grey;
}

.people{
  width: 100%;
}
/* breack pointsK */
@media only screen and (max-width: 600px) {
  .form-margin-login {
    flex-direction: column;
    margin: 0;
    justify-content: center;
  }
  .lado {
    width: 65%;
  }
  .title-form {
    font-size: 2rem;
    margin-top: 30px;
  }
  .redes {
    width: 300px;
    display: flex;
    flex-direction: row;
    font-size: 0.8rem;
    flex-wrap: nowrap;
  }
  .facebook {
    width: 130px;
  }
  .facebook i {
    padding-right: 8px;
  }
  .google {
    width: 130px;
    height: 52px;
    padding: 5px;
    margin-left: 10px;
  }
  .google i {
    padding-right: 10px;
  }
  .people {
    width: 75%;
  }
  
}

@media only screen and (max-width: 320px) {
  .title-form {
    font-size: 1.8rem;
    margin-top: 20px;
  }
  .btn-plus-form {
    margin: 10px 10% 0 10%;
  }
  .redes {
    flex-wrap: nowrap;
  }
  .flex-column .formInput {
    font-size: 0.8rem;
  }
  .lado .formInput {
    font-size: 0.8rem;
  }
  .lado .MuiOutlinedInput-input {
    font-size: 0.8rem;
  }
}




input[type="password"] {
  font-family: sans-serif;
}