.progresiveBar {
  display: flex;
  justify-content: right;
  
}
.box {
  position: relative;
  .progresive {
    width: 120px;
    .ant-progress-inner{
      background-color: #7e7e80;
    }
    .ant-progress-text{
      margin-left: -120px;
      .texto{
        padding: 0;
        position: relative;
        top: 35px;
        font-size: 1rem;
      }
    }
    .ant-progress-success-bg{
      background-color: #57D4FD;
    }
    
  }
  .ant-progress-status-success .ant-progress-text{
    color: #57D4FD;
  }

  .circulo{
    position: absolute;
    top: 0;
    left: -35px;
    .ant-progress-circle-trail {
      stroke: #7e7e80;
    }
  }
}

.name{
  display: none;
}

@media(max-width: 1024px){
  .box2{
    align-items: flex-start !important;
    padding-left: 40px;
    .progresiveBar{
      .box{
        .progresive{
          width: 80px;
          .ant-progress-text{
            .texto{
              left: 35px;
              font-size: 12px !important;
            }
          }
        }
        
      }
    }
  }
  .btns{
    margin-top: 50px;
  }
}

@media(max-width: 640px){
  .ContainerCampain{
    .boxes{
      .box2{
        align-items: center !important;
        padding: 0;
        .progresiveBar{
          flex-direction: column;
          gap: 35px;
          margin-bottom: 20px;
          .box{
            .progresive{
              .ant-progress-outer{
                transform: rotate(90deg);
                .ant-progress-inner{
                  width: 25px;
                  bottom: -60px;
                  left: 2px;
                }
              }
              .ant-progress-text{
                .texto{
                  top: 4px;
                  left: 80px;
                }
              } 
            }
          }
        }
        .btns{
          justify-content: center !important;
        }
      }
    }
  }
}