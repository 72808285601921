.maleWrap {
    display: flex;
    padding: 10px 0 10px;
  }
  .maleWrap .calcGrid i.active,
  .maleWrap .bigIco {
    color: #7ADBD4;
  }
  
  
  
  .femaleWrap {
    display: flex;
  }
  .femaleWrap .calcGrid i.active,
  .femaleWrap .bigIco {
    color: #FF9015;
  }
  
  
  
  .bigIco {
    width: 15%;
    text-align: center;
    font-size: 3em;
  }
  
  
  
  .calcGrid {
    width: 60%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }
  
  
  
  .calcResult {
    width: 25%;
    text-align: center;
    font-size: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
  }
  
  
  
  .calcGrid i {
    flex: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.6em;
    color: #7E7E80;
  }
.boxGenderSocialNetwork{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 15px;
  margin-top: 20px;
}
.boxGenderSocialNetwork .box{
  display: flex;
}
.boxGenderSocialNetwork .box img{
  width: 30px;
  height: 30px;
  margin-right: 10px;
}
.boxGenderSocialNetwork .box div p{
  margin: 0;
  width: 60px;
  display: flex;
  font-size: 12px !important;
  justify-content: space-between;
}
.boxGenderSocialNetwork .box div p span{
  font-size: 12px;
}