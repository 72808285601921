.kep-login-facebook.metro{
  border-radius: 20px !important;
}

.kep-login-facebook {
  background-color: #1877f2 !important;
}


.row {
  margin-top: 10px;
}
h3 {
  margin-top: 35px;
}

.container-posts {
  display: flex;
  margin-top: 50px;
  margin-bottom: 20px;
}

.container-posts p {
  width: 120px;
  display: inline-block;
  color: orangered;
  font-weight: 600;
  margin-left: 10px;
  
  overflow: hidden;
  text-overflow: Ellipsis;
}
.container-posts p span {
  color: black;
  font-weight: 400;
}
.container-posts .url {
  width: 120px;
}

.validator {
  width: 90px;
  height: 40px;
  background-color: #78797a;
  display:block;
  margin: 15px 0 0 0;
  padding: 7px;
  border-radius: 5px;
  color: white;
}

.text-validacion {
  color: red;
}