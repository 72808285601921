.react-google-flight-datepicker .dialog-date-picker {
    top: -455px !important;
}

.blur-div .btnOrange-dash {
    margin-left: 0px !important;
    margin-bottom: 10px !important;
}
.blur-div .container {
    display: block !important;
}

.blur-div .file-status-bar {
    font-size: 16px !important;
    margin-bottom: 2px !important;
}
.blur-div .file-type-logo {
    width: 35px !important;
    height: 45px !important;
}
.rowsubtask {
    text-align: end !important;
    font-weight: normal !important;
}

.inputPresupuesto{
    width: 120px !important;
    height: 40px !important;
    border: 1px solid #b8b8ba;
    opacity: 1 !important; 
    padding: 20px;
    background-color: transparent;
    color: #000 !important;
}
.inputPresupuesto:hover{
    border: 1px solid #000;
}

.file-status-bar{
    width: 60% !important;
}
.contenedorRangoEco{
    flex-direction: column;
    margin: 40px 0 !important;
}
.TituloRango{
    height: 34px;
}
.contenedorRangoEco .contendorSelect{
    height: 34px;
}
.contenedorRangoEco .contendorSelect .col-sm-5{
    height: 34px;
}
.contenedorRangoEco 
.contendorSelect 
.col-sm-5
.css-11mde6h-MuiPaper-root{
    margin: 0 !important;
}

.MuiNativeSelect-select.MuiNativeSelect-select{
    padding-left: 7px;
}

@media (max-width: 1024px){
    .react-google-flight-datepicker {
        .dialog-date-picker{
            width: 100%;
        }
    }
    
}
@media (max-width: 768px){
    .react-google-flight-datepicker .dialog-date-picker{
        top:  400px !important;
        width: 60%;
        height: 50%;
        left: 10%;
    }
    .react-google-flight-datepicker .dialog-date-picker.hide{
        left: -150%;
    }
    .txtSpacing{
        margin-top: 15px !important;
    }
}
@media (max-width: 640px){
    .selectAge{
        width: 50% !important;
    }
    .selectSocialStatus{
        width: 50% !important;
    }
    .react-google-flight-datepicker .dialog-date-picker{
        width: 80%;
    }
    .rowsubtask{
        display: none;
    }
}