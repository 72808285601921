@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');

@font-face {
  font-family:PierSans;
  src: url(../fonts/PPPierSans-Bold.otf);
}
@font-face {
  font-family:PierSans;
  src: url(../fonts//PPPierSans-Regular.otf);
}

body {
  font-family: 'PierSans', sans-serif;
}

html::-webkit-scrollbar {
  width: 10px;
  height: 20px;
}


html::-webkit-scrollbar-thumb {
  width: 3px;
  background: linear-gradient(180deg, #57D4FD, #57D4FD);
  border-radius: 20px;
  box-shadow: inset 2px 2px 2px hsla(0, 0%, 100%, .25), inset -2px -2px 2px rgba(0, 0, 0, .25)
}

html::-webkit-scrollbar-track {
  background: linear-gradient(90deg, #ccc, #c2c2c2 1px, #ccc 0, #b1b1b1)
}

input:focus {
  outline: none !important;
  outline-width: 0 !important;
  box-shadow: auto !important;
  -moz-box-shadow: auto !important;
  -webkit-box-shadow: auto !important;
}

p {
  margin-top: 0;
  margin-bottom: 0;
}

.container {
  
  display: flex !important;
  ._buttons_container_ct0vw_11 {
    height: 46px !important;
    ._button_share_ct0vw_16, ._button_calendar_ct0vw_17, ._button_show_column_ct0vw_18, ._button_filter_column_ct0vw_19 {
      padding: 5px 10px 6px 10px !important;
    
  }
  }
  a#colum-btn {
    height: 46px;
  }
  .container-fluid, .container-sm, .container-md, .container-lg, .container-xl{
    padding-right: 0px;
  }
  a#filter-btn {
    height: 46px;
    padding-top: 12px;
  }
}
.btnOrange-dash {
  position: relative!important;
  z-index: 6!important;
  background: #FF9015!important;
  padding: 2px 9.5px!important;
  color: #fff!important;
  font-size: 28px!important;
  height: 46px!important;
  margin-left: -23px!important;
}

/*nuevo css*/

.form-dashboard {
  max-width: 80% !important;
}

.circle{
  background-color: #5CF993 !important;
}

.btnOrange-dash{
  background-color: #56FFBE !important;
}
.icon-container{
  background-color: #57D4FD !important;
}
.bar{
  background-color: #57D4FD !important;
}


.delivery-running{
  background-color: transparent !important;
}
.delivery-stop{
  background-color: transparent !important;
}
.delivery-complete{
  background-color: transparent !important;
}

/*Estado tareas*/
.deliveryDesconocido{
  width: 100px;
  height: 30px;
  background-color: #80949b;
  border-radius: 5px;
  text-align: center;
  color: #343a40;
  font-weight: bold;
  line-height: 30px;
}
.deliverySolicitado{
  width: 100px;
  height: 30px;
  background-color: #80949b;
  border-radius: 5px;
  text-align: center;
  color: #343a40;
  font-weight: bold;
  line-height: 30px;
}
.deliveryPending{
  width: 100px;
  height: 30px;
  background-color: #80949b;
  border-radius: 5px;
  text-align: center;
  color: #343a40;
  font-weight: bold;
  line-height: 30px;
}
.deliveryPendiente{
  width: 100px;
  height: 30px;
  background-color: #80949b;
  border-radius: 5px;
  text-align: center;
  color: #343a40;
  font-weight: bold;
  line-height: 30px;
}
.deliveryEn{
  width: 100px;
  height: 30px;
  background-color: #80949b ;
  border-radius: 5px;
  text-align: center;
  color: #343a40;
  font-weight: bold;
  line-height: 30px;
}
.deliveryEnRevision{
  width: 100px;
  height: 30px;
  background-color: #80949b ;
  border-radius: 5px;
  text-align: center;
  color: #343a40;
  font-weight: bold;
  line-height: 30px;
}

.deliveryActive{
  width: 100px;
  height: 30px;
  text-align: center !important;
  line-height: 30px !important;
  background-color: #5cf993;
  border-radius: 5px;
  color: #343a40;
}
.deliveryActiva{
  width: 100px;
  height: 30px;
  text-align: center !important;
  line-height: 30px !important;
  background-color: #5cf993;
  border-radius: 5px;
  color: #343a40;
}
.deliveryActivo{
  width: 100px;
  height: 30px;
  text-align: center !important;
  line-height: 30px !important;
  background-color: #5cf993;
  border-radius: 5px;
  color: #343a40;
}
.deliveryAprobado{
  width: 100px;
  height: 30px;
  background-color: #5cf993;
  border-radius: 5px;
  text-align: center;
  color: #343a40;
  font-weight: bold;
  line-height: 30px;
}
.deliveryCompleted{
  width: 100px;
  height: 30px;
  background-color: #57d4fd;
  border-radius: 5px;
  text-align: center;
  color: #343a40;
  font-weight: bold;
  line-height: 30px;
}
.deliveryCompletado{
  width: 100px;
  height: 30px;
  background-color: #57d4fd;
  border-radius: 5px;
  text-align: center;
  color: #343a40;
  font-weight: bold;
  line-height: 30px;
}

.deliveryCerrada{
  width: 100px;
  height: 30px;
  background-color: #80949b;
  border-radius: 5px;
  text-align: center;
  color: #fff;
  font-weight: bold;
  line-height: 30px;
}
.deliveryFinalizada{
  width: 100px;
  height: 30px;
  background-color: #56ffbe;
  border-radius: 5px;
  text-align: center;
  color: #343a40;
  font-weight: bold;
  line-height: 30px;
}
.deliveryFinalizado{
  width: 100px;
  height: 30px;
  background-color: #56ffbe;
  border-radius: 5px;
  text-align: center;
  color: #343a40;
  font-weight: bold;
  line-height: 30px;
}
.deliveryPausado{
  width: 100px;
  height: 30px;
  background-color: #ffde59;
  border-radius: 5px;
  text-align: center;
  color: #343a40;
  font-weight: bold;
  line-height: 30px;
}
.deliveryPausada{
  width: 100px;
  height: 30px;
  background-color: #ffde59;
  border-radius: 5px;
  text-align: center;
  color: #343a40;
  font-weight: bold;
  line-height: 30px;
}

.deliveryRejected{
  width: 100px;
  height: 30px;
  background-color: #f08c92;
  border-radius: 5px;
  text-align: center;
  color: #343a40;
  font-weight: bold;
  line-height: 30px;
}
.deliveryRechazado{
  width: 100px;
  height: 30px;
  background-color: #f08c92;
  border-radius: 5px;
  text-align: center;
  color: #343a40;
  font-weight: bold;
  line-height: 30px;
}
.deliveryAcreditado{
  width: 100px;
  height: 30px;
  background-color: #b3f993;
  border-radius: 5px;
  text-align: center;
  color: #343a40;
  font-weight: bold;
  line-height: 30px;
}
.delivery-tail{
  background-color: transparent !important;
}
/*tags campañas*/
.deliverycampaignEn{
  width: 100px;
  height: 30px;
  background-color: #80949b;
  border-radius: 5px;
  text-align: center;
  color: #343a40;
  font-weight: bold;
  line-height: 30px;
}
.deliverycampaignActiva{
  width: 100px;
  height: 30px;
  background-color: #5cf993;
  border-radius: 5px;
  text-align: center;
  color: #343a40;
  font-weight: bold;
  line-height: 30px;
}
.deliverycampaignCompletado{
  width: 100px;
  height: 30px;
  background-color: #57d4fd;
  border-radius: 5px;
  text-align: center;
  color: #343a40;
  font-weight: bold;
  line-height: 30px;
}
.deliverycampaignFinalizada{
  width: 100px;
  height: 30px;
  background-color: #56ffbe;
  border-radius: 5px;
  text-align: center;
  color: #343a40;
  font-weight: bold;
  line-height: 30px;
}
.deliverycampaignCerrada{
  width: 100px;
  height: 30px;
  background-color: #80949b;
  border-radius: 5px;
  text-align: center;
  color: #fff;
  font-weight: bold;
  line-height: 30px;
}
.deliverycampaignPausada{
  width: 100px;
  height: 30px;
  background-color: #ffde59;
  border-radius: 5px;
  text-align: center;
  color: #343a40;
  font-weight: bold;
  line-height: 30px;
}


/*tags Tareas*/
.deliverytaskSolicitado{
  width: 100px;
  height: 30px;
  background-color: #80949b;
  border-radius: 5px;
  text-align: center;
  color: #343a40;
  font-weight: bold;
  line-height: 30px;
}
.deliverytaskPendiente{
  width: 100px;
  height: 30px;
  background-color: #ffde59;
  border-radius: 5px;
  text-align: center;
  color: #343a40;
  font-weight: bold;
  line-height: 30px;
}
.deliverytaskEn{
  width: 100px;
  height: 30px;
  background-color: #56ffbe;
  border-radius: 5px;
  text-align: center;
  color: #343a40;
  font-weight: bold;
  line-height: 30px;
}
.deliverytaskRechazado{
  width: 100px;
  height: 30px;
  background-color: #ff5757;
  border-radius: 5px;
  text-align: center;
  color: #343a40;
  font-weight: bold;
  line-height: 30px;
}
.deliverytaskRejected{
  width: 100px;
  height: 30px;
  background-color: #ff5757;
  border-radius: 5px;
  text-align: center;
  color: #343a40;
  font-weight: bold;
  line-height: 30px;
}
.deliverytaskAprobado{
  width: 100px;
  height: 30px;
  background-color: #5cf993;
  border-radius: 5px;
  text-align: center;
  color: #343a40;
  font-weight: bold;
  line-height: 30px;
}
.deliverytaskFinalizado{
  width: 100px;
  height: 30px;
  background-color: #57d4fd;
  border-radius: 5px;
  text-align: center;
  color: #343a40;
  font-weight: bold;
  line-height: 30px;
}
.deliverytaskAcreditado{
  width: 100px;
  height: 30px;
  background-color: #80949b;
  border-radius: 5px;
  text-align: center;
  color: #fff;
  font-weight: bold;
  line-height: 30px;
}
.deliverytaskPagado{
  width: 100px;
  height: 30px;
  background-color: #5cf993;
  border-radius: 5px;
  text-align: center;
  color: #343a40;
  font-weight: bold;
  line-height: 30px;
}




.titleOneStepClose{
  .fa-angle-double-right{
    color: #57D4FD !important;
  }
}
.titleOneStep{
  .fa-angle-double-left{
    color: #57D4FD !important;
  }
  p{
    color: #57D4FD !important;
  }
}

.settings-nav-itemClose:hover{
  background-color: #b0ffe1 !important;
  border-color: #57D4FD !important;
  .settings-nav-link{
    i{
      color: #57D4FD !important;
    }
  }
}

.settings-nav-itemClose ul li a:hover{
  color: #57D4FD !important;
  border-color: #57D4FD !important;
}
.settings-nav{
  .settings-nav-item:hover > a{
    color: #57D4FD !important;
  } 
}



.settings-nav-item ul li a:hover{
  border-color: #57D4FD !important;
  color: #57D4FD !important;

}

.settings-nav-item:hover > a{
  background-color: #b0ffe1 !important;
  .settings-nav-link{
    color: #57D4FD !important
  }
  i{
    color: #57D4FD !important;
  }
}

.buttons-Modal-content button:nth-child(1){
  background-color: #80949B !important;
}
.buttons-Modal-content button:nth-child(2){
  background-color: #57D4FD !important;
}
.title-grid::before{
  background-color: #56FFBE !important;
}

.maleWrap .calcGrid i.active, .maleWrap .bigIco{
  color: #57D4FD !important;
}
.femaleWrap .calcGrid i.active, .femaleWrap .bigIco{
  color: #5CF993 !important;
}

.numberValues{
  color: #57D4FD !important;
}

.title-text{
  font-size: 1.3rem !important;
}

.description{
  color: #57D4FD !important;
}

.Head .btnBack{
  font-family: Piersans !important;
}

.title-grid{
  font-family: Piersans;
  font-size: 1.3rem;
  font-weight: 700;
}
.boxGrafica{
  display: flex;
  justify-content: center;
  align-items: center;
}

button{
  font-family: Piersans !important;
}
p{
  font-family: Piersans !important;
}
span{
  font-family: Piersans !important;
}
div{
  font-family: Piersans !important;
}
a{
  font-family: Piersans !important;
}



.targetContent-small .marginTarget{
  overflow-y: auto;
  height: 250px;
  &::-webkit-scrollbar {
    width: 8px;     /* Tamaño del scroll en vertical */
    height: 8px;    /* Tamaño del scroll en horizontal */
  }
  &::-webkit-scrollbar-thumb {
    background: #57D4FD;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb:active {
    background-color: #999999;
  }
}
.targetContent-small .marginTarget .dataBox, .targetContent-small .marginTarget .dataBox, .targetContent .marginTarget .dataBox{
  height: auto !important;
}

input[type="password"] {
  font-family: sans-serif;
}

.btn-plus-form{
  background-color: #56FFBE;
}
.dataLabels{
  margin-top: 10px !important;
}

.progressBar p{
  font-size: 0.6rem !important;
}

button{
  font-size: 14px !important;
}
.text1{
  font-size: 12px !important;
}
.text2{
  font-size: 12px !important;
}
.dataBox{
  a{
    cursor: default !important;
    font-size: 16px !important;
  }
}

.title-text{
  font-size: 16px !important;
}
.rectangle .title{
  font-size: 12px !important;
}
.rectangle .number{
  font-size: 24px !important;
}
.rectangle .description{
  font-size: 12px !important;
}
.rectangle-img .number{
  font-size: 24px !important;
  font-weight: bold;
  margin-top: 27px !important;
}
.rectangle-img-info .number{
  font-size: 24px !important;
  font-weight: bold;
  margin-top: 16px !important;
}
.calcResult p{
  font-size: 24px !important;
  margin: 0 !important;
}
.dataLabels .dataBox span{
  font-size: 12px !important;
}
.dataLegend .dataBox p{
  font-size: 16px !important;
}
.title-grid{
  font-size: 16px;
}

.hashtagTop {
  width: 100%;
  padding: 20px;
  .targetContent-small {
    box-shadow: none;
    .marginTarget{
      width: 100% !important;
    }
  }
}

/*fin nuevo css*/








.cursor-pointer {
  cursor: pointer;
}

.shadow {
  background: rgba(0, 0, 0, .4392156862745098);
  position: fixed;
  z-index: 5;
  top: 0;
  height: 100vh;
}

.DdiNg {
  min-width: 150px;
}

.DdiNg>div>p {
  width: 100%;
}

.fas.fa-edit {
  margin-right: 0;
}

.rectangle-img-info-small .text {
  line-height: 1.8;
}

.catEditFind {
  background: #ff3d57;
  color: #fff;
  padding: 10px;
  position: absolute;
  cursor: pointer;
  left: auto;
  top: 6px;
  display: flex;
  justify-content: flex-end;
  border-radius: 3px;
  right: calc(100% - 15px) !important;
  transition: all 0.5s ease;
  padding-right: 15px !important;
}

.catEditFind:hover {
  border-radius: 0 5px 5px 0;
  left: 0;
}

.catEditFind.icons-2:hover {
  right: calc(100% - 70px) !important;
}

.catEditFind.icons-3:hover {
  right: calc(100% - 95px) !important;
}

.catEditFind i {
  margin-left: 10px;
}

.DdiNg {
  align-items: center;
  justify-content: center;
  text-align: center;
}

.kRTDwm,
.gHcsot {
  justify-content: center;
}

._2P76T {
  display: flex;
  width: 100%;
  margin: 10px 0 0;
}

.select-dashboard {
  box-shadow: 0px 1px 5px hsla(0, 0%, 50.2%, .5019607843137255);
  background: #fff;
  border: 2px solid #fff;
  font-size: 0.9em;
  width: 20%;
  margin: 0;
  margin-bottom: 10px;
}

.form-control {
  padding: .475rem .75rem;
}

.secondColl {
  text-align: center;
  border-radius: 5px;
  color: #fff;
  font-weight: 400;
  padding: 10px 5px;
  word-break: normal;
  min-width: 150px;
}

.dropdown,
.dropleft,
.dropright,
.dropup {
  position: relative;
  box-shadow: 0px 1px 5px hsla(0, 0%, 50.2%, .5019607843137255);
  background: #fff;
  border: 2px solid #fff;
  padding: 2px 10px;
  border-radius: 5px;
}

.facebook {
  margin: 0 auto 10px !important;
}

.google,
.facebook {
  max-width: 260px;
}

.google {
  box-shadow: 0 0px 5px #dbdbdb;
}

.input-group>.form-control {
  background: #fff;
}

.redes a {
  margin: 1px !important;
  font-size: 0.9em;
}

.passForget a {
  font-size: 0.8em;
}

.MuiAutocomplete-root.MuiAutocomplete-hasClearIcon.MuiAutocomplete-hasPopupIcon {
  height: auto !important;
  width: 100% !important;
}

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  padding: 0 !important;
}

.css-1pahdxg-control {
  height: 50px;
}

.css-yk16xz-control {
  height: 50px;
  border-bottom: 1px solid grey;
  box-shadow: 0 1px 5px hsla(0, 0%, 50.2%, .501961);
}

.css-1hwfws3 {
  height: 50px;
}

.tagCont p {
  color: #fff !important;
}

.titleOneStep {
  font-size: 24px;
  display: flex;
  flex-flow: row;
  align-items: center;
  width: 95%;
}

div#new-pauta-modal {
  padding: 0 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px #343a40;
}

.dataPick {
  height: 51px !important;
}

.contDate,
.dataPick {
  display: flex !important;
  align-items: flex-end !important;
  justify-content: flex-start !important;
}

.DateRangePicker_picker {
  z-index: 9 !important;
  background-color: #fff !important;
  position: absolute !important;
  left: 0% !important;
}

.DayPicker_transitionContainer {
  margin-bottom: 10px;
}

.bodyOneStep.w-100::-webkit-scrollbar {
  width: 10px;
  height: 20px
}

.bodyOneStep.w-100::-webkit-scrollbar-thumb {
  background: linear-gradient(180deg, #FF642E, #FF642E);
  border-radius: 20px;
  box-shadow: inset 2px 2px 2px hsla(0, 0%, 100%, .25), inset -2px -2px 2px rgba(0, 0, 0, .25)
}

.bodyOneStep.w-100::-webkit-scrollbar-track {
  border-radius: 20px;
  background: linear-gradient(90deg, #ccc, #c2c2c2 1px, #ccc 0, #b1b1b1)
}

.bodyOneStep::-webkit-scrollbar {
  width: 6px;
  height: 30px
}

.bodyOneStep::-webkit-scrollbar-thumb {
  background: linear-gradient(180deg, #FF642E, #FF642E);
  border-radius: 20px;
  box-shadow: inset 2px 2px 2px hsla(0, 0%, 100%, .25), inset -2px -2px 2px rgba(0, 0, 0, .25)
}

.bodyOneStep::-webkit-scrollbar-track {
  border-radius: 20px;
  background: linear-gradient(90deg, #ccc, #c2c2c2 1px, #ccc 0, #b1b1b1)
}

.MuiAutocomplete-inputRoot[class*=MuiOutlinedInput-root] {
  height: 50px;
}

.MuiAutocomplete-inputRoot[class*=MuiOutlinedInput-root] input {
  height: 50px;
  border-radius: 5px;
}

.MuiOutlinedInput-input {
  padding: 0 8px !important;
}

._1vTf4 {
  color: darkgrey;
}

.form-check-input {
  width: auto !important;
  height: auto !important;
  opacity: 1 !important;
}

.MuiAutocomplete-popper {
  z-index: 9999999 !important;
}

.settings-nav {
  padding: 0 0 0 0 !important;
  transition: 0.5s;
  left: -12px;
}

.settings-nav .titleOneStep {
  padding: 20px 0 0 85px;
  font-size: 16px;
}

.settings-nav-item a {
  color: #000;
  width: 100%;
  display: flex;
  flex-flow: row-reverse;
  transition: all 0.5s ease;
  justify-content: space-between;
}

.settings-nav-item {
  margin: 0px 0;
  position: relative;
  padding: 10px 0 10px 85px;
  transition: all 0.5s ease;
}

.settings-nav-item:hover {
  background: #f9875f;
  color: #fff;
}

.settings-nav-item:hover a {
  color: #fff;
}

.settings-nav-itemClose {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  font-size: 25px;
}

.settings-nav-itemClose a {
  color: #000;
}

.settings-nav-itemClose:hover {
  background: #f9875f;
}

.settings-nav-itemClose:hover a {
  color: #fff;
}

.titleOneStepClose {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  height: 50px;
  font-size: 25px;
}

.titleOneStep i {
  margin: 0 10px 0 0;
}

.settings-nav-item i {
  float: right;
  margin: 6px 15px 0 0;
  font-size: 0.8em;
}

.img-content-creativy {
  display: flex;
}

.imagenCreati {
  height: 50px;
  position: relative;
  margin-left: 15px;
}

.imagenCreati i {
  position: absolute;
  right: 0;
  color: white;
  cursor: pointer;
  background: red;
  width: 16px;
  height: 16px;
  text-align: center;
  border-radius: 50%;
  box-shadow: 0 0 2px black;
}

.OneColBody,
.twoColBody {
  padding: 0 5px;
}

.modal-newCamp {
  position: fixed;
}

.edit-fc {
  background: #ff3d57;
  color: #fff;
  padding: 10px;
  position: absolute;
  cursor: pointer;
  left: -45px;
  top: auto;
}
.firstColl {
  position: relative;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  align-items: center;
  align-content: center;
}
._37QqY {
  height: 60px;
  display: flex;
  align-items: center;
  width: 70px;
  text-align: center;
  justify-content: center;
}

.MuiAutocomplete-inputRoot .MuiAutocomplete-input {
  box-shadow: 3px 3px 5px hsla(0, 0%, 50.2%, .5019607843137255);
}

.component h1.title {
  font-size: 2.3rem;
}

.space-btns.padd .facebook {
  margin: 0 !important;
  padding: 10px 10px;
}

.space-btns.padd .google {
  margin: 0 !important;
}

.form-dashboard .container.m-2.p-2 {
  max-width: 100% !important;
  background: #EFF0F2;
  box-shadow: 5px 5px 15px #0000001F;
  border: 2px solid #FFFFFF;
  border-radius: 10px;
}

.form-dashboard .d-flex.w-100 ._3fTZM {
  padding: 0;
}

._3fTZM.rdt_Utils_Container.container {
  padding-right: 0;
}

.titleOneStep i,
.titleOneStepClose i {
  cursor: pointer;
}

.navbar .navbar-brand img {
  object-fit: contain;
}

._3LnVt ._2O0q6 {
  padding: 10px 5px 10px;
}

.rounded.form-control {
  margin: 10px 0 0;
}

._3LnVt,
._16c_4>div {
  padding: 0 5px;
}

#new-audience-modal {
  max-width: 90%;
}

.account-selector__control.css-yk16xz-control {
  cursor: pointer;
}

._DSojl a:hover {
  color: #02025b !important;
}

.formInput {
  box-shadow: 1px 2px 5px hsla(0, 0%, 50.2%, .5019607843137255);
  margin: 5px 0 10px;
  outline: 0;
}


#viewSelect {
  cursor: pointer;
}

.people {
  width: 90%;
}

.navbar {
  border-radius: 0 0 15px 15px;
}

.swal2-popup {
  border-radius: 10px;
}

.oneColBody input,
.oneColBody select,
.twoColBody input,
.twoColBody select {
  margin: 0;
  min-height: 50px;
}

#new-category-modal,
#new-audience-modal,
div#new-account-modal {
  max-width: 80%;
  border-radius: 10px;
}

.form-group {
  margin-bottom: 1rem;
  padding: 0 5px;
}

.navbar-collapse {
  background: #fff;
  padding: 0 10px 0;
  display: flex;
  justify-content: space-between;
}

._1nB_u {
  width: 90%;
  margin: 0 auto;
}

._1nB_u a {
  margin: 0 10px 0 0;
}

.navbar .container {
  max-width: 1300px !important;
}

.form-dashboard .container.m-2.p-2 {
  margin: 10px 0 !important;
}

.iAwzhW {
  padding: 0 0;
}

.content-menu {
  flex-flow: row wrap;
}

#new-audience-modal, #new-category-modal, div#new-account-modal {
  max-width: 1080px;
}

.tagsContent {
  height: 39px;
}

.dXaTQl {
  padding: 0 0 0 15px;
}



@media (max-width: 1025px) {
  #new-audience-modal, #new-category-modal, div#new-account-modal {
    max-width: 80%;
  }

  .form-margin {
    width: 80%;
    justify-content: space-between;
  }

  .space-btns.padd .facebook {
    font-size: 0.7em;
  }

  .space-btns.padd .google {
    font-size: 0.7em;
    padding: 5px 10px;
  }
}

@media (max-width: 991px) {
  .bodyOneStep {
    height: calc(95% - 150px) !important;
  }

  .pl-5,
  .px-5 {
    padding-left: 1rem !important;
  }

  .pr-5,
  .px-5 {
    padding-right: 1rem !important;
  }

  .navbar .container {
    padding: 0;
  }

  .css-1uccc91-singleValue {
    font-size: 0.8em;
  }

  .navbar .account-selector-container {
    min-width: 130px;
  }

  .bodyOneStep.w-100::-webkit-scrollbar {
    width: 6px;
    height: 30px
  }

  div#new-pauta-modal {
    display: flex;
    flex-flow: column;
    padding: 10px;
    width: 90% !important;
  }

  .contDate,
  .dataPick {
    display: inline-block !important;
    align-items: flex-end !important;
    justify-content: flex-start !important;
    margin: 0 0 0px;
  }

  .DateInput {
    margin: 0;
    padding: 0;
    position: relative;
    display: inline-block;
    width: 110px;
    vertical-align: middle;
  }

  .oneColBody input,
  .oneColBody select,
  .twoColBody input,
  .twoColBody select {
    min-height: 50px;
    background: #fff;
  }

  .oneColBody {
    margin: 10px 0 0;
  }

  .footOneStep {
    flex-flow: column;
    padding: 5px 0 0;
    margin: 0 0 10px;
    width: 89%;
  }

  .footOneStep a {
    width: 100%;
    text-align: center;
    margin: 10px 0 0px;
  }

  .footOneStep a:first-child {
    width: 100%;
    text-align: center;
    margin: 0;
  }

  .bodyOneStep {
    margin: 0 0 10px;
    width: 96.666%;
  }

  .Section__container___3YYTG .form .form-margin {
    flex-flow: column;
    width: 100% !important;
  }

  .Section__container___3YYTG .form .form-margin .left-form {
    width: 100%;
    margin: 70px auto 0;
  }

  .Section__container___3YYTG .form .form-margin .right-form {
    width: 90%;
    margin: 20px 0;
  }

  .space-btns.padd .google {
    margin: 10px 0;
  }
}


