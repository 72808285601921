.navbar {
  height: 60px;
  background: #fff;
  color: #fff;
  box-shadow: 0 0 15px hsla(0, 0%, 50.2%, .65);
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;
  z-index: 15;

  .container,
  .navbar-brand {
    height: 100%;
  }

  .container {
    width: 100%;
  }

  .nav-link {
    font-size: 1rem;
    font-weight: 700;
  }

  .nav-options {
    .tab.nav-item {
      margin: 0 8px;
    }
  }

  .account-selector-container {
    font-size: 0.8rem;
    min-width: 160px;
    font-weight: bold;

    .account-selector__control {
      border: none;
      box-shadow: none;
    }

    .account-selector__value-container {
      justify-content: center;
    }
  }
}

.container-fluid{
  display: block !important;
}