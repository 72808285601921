.part2 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.logoAdvertiser{
  width: 400px;
  opacity: 0.5;
}

.tabs {
  display: flex;
  margin: 10px 0 0;
  justify-content: space-around;
  list-style: none;
  cursor: hand;
}
.tabitem__title {
  cursor: hand;

}

.title-tabPane {
  margin-bottom: 20px;
}
.end-part {
  margin-top: -70px;
}
.bodyNewAdvertiser{
  height: 75% !important;
}

.nav-link{
  font-size: 0.9rem;
  font-weight: bold;
  color: rgb(132, 132, 133);
  cursor: pointer;
}
.nav-link:hover{
  color: #57D4FD;
}
.activeTab{
  color: #57D4FD;
  border-bottom: 4px solid #57D4FD;
}

.btnBurger{
  display: none;
}
.inputCheck{
  display: none;
}
@media (max-width: 1400px){
  .bodyNewAdvertiser{
    margin-top: 0;
    height: 365px !important;
  }
  
  .FooterModal{
    padding: 0 4% !important;
  }
}
@media (max-width: 1024px){
  .tabs{
    .nav-link{
      font-size: 12px;
      padding: 8px 8px;
    }
  }
  .logoAdvertiser{
    width: 300px !important;
    margin-top: 10px;
  }
  .footerModalNewAdvertiser{
    margin-top: 35px;
  }
  
}
@media (max-width: 640px){
  .modalNewAdvertiser{
    position: relative;
  }
  .btnBurger{
    display: block;
    position: relative;
    left: 20px;
    top: 10px;
    width: 30px;
    height: 30px;
    background-color: #fff;
    box-shadow: 5px 5px 15px #00000029;
    border-radius: 5px;
    i{
      color: #000;
      text-align: center;
      margin-left: calc(50% - 7px);
      line-height: 30px;
    }
  }
  .inputCheck{
    display: none;
    &:checked ~ .menuMobil {
     display: block;
    }
  }
  .menuMobil{
    display: none;
  }
  .tabs{
    position: absolute;
    display: grid !important;
    grid-template-columns: 1fr 1fr;
    background-color: #fff;
    justify-content: center;
    opacity: 1; 
    width: 90%;
    left: calc(50% - 45%);
    top: 92px;
    .nav-link{
      font-size: 12px;
      text-align: center;
    }
    .activeTab{
      color: #57D4FD;
      border: none;
    }
  }
  .part2{
    display: none !important;
  }
  .footerModalNewAdvertiser{
    margin-top: 50px;
  }
}