.fc-toolbar-chunk {
  
  .fc-prev-button,
  .fc-next-button {
    background-color: #ff642e;
    border-color: #ff642e;
  }
}
.fc-event-time {
  display: none;
}