.fixText .dataBox{
    height: auto !important;
    word-wrap: break-word !important;
}
.buttons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .container-boxes {
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-bottom: 70px;
  }
  
  .texto {
    padding: 20px;
  }

  .p-btn-back{
    padding-left: 20px;
  }

  .p-btn-right{
    padding-right: 82px;
  }
  .descripcionCampania{
    white-space:pre-wrap;
    line-height: 1.5;
    /* max-height: 700px; */
    min-height: 300px;
  }
  
  .descripcionCampania p{
    word-wrap: break-word;
    font-size: 1rem;
  }


.Head{
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: sticky;
  .btnBack{
    font-style: normal;
    font-weight: bold;
    font-size: 1.2rem;
    color: #05252D;
    cursor: pointer;
    img{
      width: 40px;
    }
  }
  h2{
    font-size: 1.5rem;
    font-weight: bold;
    color: #05252D;
    text-transform: uppercase;
  }
}


.container-boxes{
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 50px;
  margin: 2rem 0;
  .box{
    background-color: #fff;
    padding: 15px;
    box-shadow: 5px 5px 10px #0000001f;
    border-radius: 5px;
    height: 332px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 8px;     /* Tamaño del scroll en vertical */
      height: 8px;    /* Tamaño del scroll en horizontal */
    }
    &::-webkit-scrollbar-thumb {
      background: #57D4FD;
      border-radius: 4px;
      cursor: pointer;
    }
    &::-webkit-scrollbar-thumb:active {
      background-color: #999999;
    }
    .content{
      border-bottom: 1px solid #CACACC;
      height: auto;
      p{
        margin: 0;
        line-height: 40px;
        span{
          font-weight: bold;
          color: #57D4FD;
        }
      }
    }
    textarea{
      width: 100%;
      height: 80%;
      border: none;
      background-color: #fff;
      color: #000;
      font-size: 1rem;
      &::-webkit-scrollbar {
        width: 8px;     /* Tamaño del scroll en vertical */
        height: 8px;    /* Tamaño del scroll en horizontal */
      }
      &::-webkit-scrollbar-thumb {
        background: #57D4FD;
        border-radius: 4px;
        cursor: pointer;
      }
      &::-webkit-scrollbar-thumb:active {
        background-color: #999999;
      }
    }
  }
  h5{
    font-weight: bold;
    color: #57D4FD;
  }
}

.container__flex{
  width: 100%;
  display: flex;
  justify-content: space-around;
  gap: 50px;
  .box-flex{
    width: 50%;
    height: 650px;
    .image-gallery{
      width: auto;
      .image-gallery-content .image-gallery-slide .image-gallery-image{
        width: 480px;
        height: 420px;
      }
      .image-gallery-thumbnails-wrapper{
        display: none;
      }
      .image-gallery-slide-wrapper{
        .image-gallery-left-nav{
          left: -60px;
          color: #04adbf;
        }
        .image-gallery-right-nav{
          width: 60px;
          right: -40px;
          color: #04adbf;
        }
      }
      .image-gallery-play-button{
        color: #04adbf;
        bottom: -50px;
        left: 40%;
      }
      .image-gallery-fullscreen-button{
        display: none;
      }
    }
    .boxContent{
      background-color: #fff;
      padding: 15px;
      box-shadow: 5px 5px 10px #0000001f;
      border-radius: 5px;
      width: 100%;
      height: 550px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.ContainerCampain{
  width: 100%;
  .boxes{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 50px;
    background-color: #fff;
    box-shadow: 5px 5px 10px #0000001f;
    border-radius: 5px;
    padding: 20px;
    .box1{
      width: 80%;
      background-color: #fff;
      box-shadow: 5px 5px 10px #0000001f;
      padding: 10px;
      border-radius: 5px;
      .content{
        border-bottom: 1px solid #CACACC;
        height: 40px;
        p{
          margin: 0;
          line-height: 40px;
          span{
            font-weight: bold;
            color: #04adbf;
          }
        }
      }
    }
    .box2{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
      .btns{
        width: 100%;
        display: flex;
        justify-content: right;
        gap: 20px;
        padding-right: 30px;
        button{
          width: 150px;
          height: 40px;
          border-radius: 5px;
          border: none;
          font-weight: bold;
          color: #fff;
          box-shadow: 5px 5px 10px #0000001f;
          cursor: pointer;
          &:nth-of-type(1){
            background-color: #57D4FD;
          }
          &:nth-of-type(2){
            background-color: #5CF993;
          }
        }
      }
    }
  }
}


.boxTables{
  width: 100%;
  margin: 2rem 0 3rem 0;
  .box:nth-child(1){
    margin-bottom: 2rem;
  }
}

.icon-container1{
  width: 61px !important;
  left: -44px !important;
  &:hover{
    left: -15px !important;
  }
}

@media (max-width: 1024px){
  .detailCampaign{
    padding-top: 2rem !important;
    .Head{
      i{
        font-size: 14px;
        width: 50%;
      }
      h2{
        font-size: 16px;
        line-height: 32px;
      }
    }
    .container-boxes{
      grid-template-columns: 1fr 1fr;
      grid-gap: 10px;
      margin-top: 0;
    }
    .container__flex{
      flex-direction: column;
      gap: 0;
      .box-flex{
        width: 100%;
        .image-gallery-image{
          width: 70% !important;
        }
        .image-gallery-right-nav{
          right: -10px !important;
        }
        .image-gallery-left-nav{
          left: -9px !important;
        }
      }
    }
    .ContainerCampain{
      .boxes{
        grid-template-columns: 1fr;
      }
    }
  }
}

@media(max-width: 640px){
  .detailCampaign{
    .Head{
      flex-direction: column;
      .btnBack{
        width: 100%;
      }
    }
    .container-boxes{
      grid-template-columns: 1fr;
    }
  }
}