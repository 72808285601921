.rectangle-graph{
    width: 742px;
/* height: 300px; */
background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 5px 5px 10px #0000001F;
border-radius: 5px;
opacity: 1;
display: flex;
justify-content: space-between;
  }

  .rectangle-graph .info{
    display: flex;
    align-items: center;
    margin-right: 20px;
  }
  
.rectangle-graph-meddium{
width: 361px;
height: 300px;
background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 5px 5px 10px #0000001F;
border-radius: 5px;
opacity: 1;
}

.rectangle-graph .doughnutChart{
  margin-left: 20px;
  height: 280px;
  width: 280px;
}

.rectangle-graph .graphGeneralStatistics{
  position: absolute;
  margin-top: 40px;
}