.boxesInputs{
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  .boxInputCustom{
    input{
      width: 70px;
      height: 30px;
    }
  }
}

.bodyPadre{
  align-items: center;
  .part-BodyModal{
    height: 400px;
  }
  .parte1{
    align-self: flex-start;
    margin-top: 2rem;
  }
  .parte2{
    display: flex;
    justify-content: center;
    align-items: center;
    img{
      width: 75%;
      height: 45%;
      opacity: 0.6;
    }
  }
}


@media (max-width: 800px){
  .parteInput{
    padding: 0 0 0 14%;
  }
  .bodyPadre .parteLogo{
    display: none !important;
  }

}
@media (max-width: 640px){
  .parteInput{
    padding: 0;
  }
  .bodyPadre .parteLogo{
    display: none !important;
  }
}