.settings-nav {
  background: #eff0f2;
}
.settings-nav-item.active {
  background: #f9875f;
  color: #fff;

  a {
    color: #fff;
  }
}

.msts_theme_example {
  .msts__control_select-all:after {
    content: '❯❯' !important;
  }
  .msts__control_deselect-all:after {
    content: '❮❮' !important;
  }
}

.select-workspace {
  width: 20%;
  border: none;
  border-radius: 5px;
  box-shadow: 3px 3px 5px #80808080;
  padding: 10px;
  color: #292f4c;
  font-weight: bold;
}