p {
    font-size: 1.1vw;
}

.select {
    border: none;
    border-radius: 15px;
    box-shadow: 3px 3px 5px hsl(0deg 0% 50% / 50%);
    padding: 5px;
    color: #05252d;
    
}

.check{
    margin-top: -10px;
}
.col-sm-1{
    padding-right: 2px;
    padding-left: 2px;
}

.row {
    margin-bottom: 10px;
}

.textPush {
    font: normal normal 700 16px/12px PierSans;
    letter-spacing: .4px;
    color: #05252d;
    opacity: 1;
    margin-bottom: 20px;
}
.char {
    padding-left: 15px;
    padding-top: 13px;
}
.first-row{
    margin-top: 20px;
    justify-items: center;
}

.title-Modal {
    font: normal normal 700 20px/12px PierSans;
    letter-spacing: .4px;
    color: #05252d;
    opacity: 1;
    margin-bottom: 10px;
}

.flex {
    display: flex;
    justify-content: space-between;
    align-items: center;

}
.txt {
    color: #05252d;
    padding-top: 1%;
    font-size: 1rem;
}

.subtitle-modal {
    color: #434343;
    font-size: 1rem;
    margin-bottom: 30px;
}

.react-google-flight-datepicker .date-picker-input {
    height: 30px ;
}


.title-Modal {
    font: normal normal 700 16px/12px PierSans;
    color: #05252d;
}

.Geo{
    margin-top: 30px;
}

.link{
    margin-top: 30px;
}


.multiselect-container {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
    font-size: 1rem;
    color: rgba(0, 0, 0, 0.87);
    line-height: 0.3;
}

.searchWrapper{
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.47) !important;
    border-radius: 0 !important;
}

.searchWrapper:hover{
    border-bottom: 2px solid rgba(0, 0, 0, 0.87) !important;
}


.searchWrapper::after{
    transform: scaleX(0);
    transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    border-bottom: 2px solid #FF9015 !important;
}

.MuiInputBase-root
.inputNumber {
    margin-top: 40px !important;
}

.boxOne {
    height: 26px;
}

.paper{
    height: 38px;
    margin-bottom: 5px;
    margin-right: 20px;
}

.paper-container {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 10px;
}