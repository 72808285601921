.content-submenu-userList {
  width: 100%;
  margin: 30px 0;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-wrap: wrap;
}
.content-submenu-userList
.selectorBox {
  margin: 10px 0;
}
.section-profile {
  width: 100%;
  margin: 30px 0;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-wrap: wrap;
}
.section-profile 
.targetContent {
  height: 380px;
}
.section-profile
.row.info {
  margin-top: 20px;
}
.section-profile
.tagContent {
  max-width: 675px;
}

.mapContent {
  max-height: 200px;
}
.galleryContent {
  max-height: 200px;
}
.content-networks{
  width: 100%;
  margin: 30px 0;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-wrap: wrap;
}
.content-networks
.row-info {
  margin-top: 20px;
}

.content-pay {
  width: 100%;
  margin: 30px 0;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-wrap: wrap;
}
.content-pay
.row-info{
  margin-top: 20px;
}

.content-actions {
  width: 100%;
  margin: 30px 0;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-wrap: wrap;
}
.content-actions
.row-info{
  margin-top: 20px;
}
.d-block {
  width: 360px;
  height: 300px;
  display: flex !important;
  justify-content: center;
}
.d-block

.hashtag{
  width: 220px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  color: white !important;
}

.d-block
.hashtag p{
  font-size: 1rem;
}

.content-subtask {
  display: flex;
}

.contenedor-subtask {
  width: 1150px !important;
  min-height: 200px !important;
  padding: 15px 0;
}



.Head{
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: sticky;
  .btnBack{
    font-style: normal;
    font-weight: bold;
    font-size: 1.2rem;
    color: #05252D;
    cursor: pointer;
    img{
      width: 40px;
    }
  }
  .btnPendiente{
    width: 180px;
    height: 40px;
    border: none;
    border-radius: 8px;
    background-color: #B3F993;
    color: #fff;
    font-weight: bold;
    box-shadow: 5px 5px 10px #0000001f;
    font-size: 14px;
  }
  h2{
    font-size: 1.5rem;
    font-weight: bold;
    color: #05252D;
    text-transform: uppercase;
  }
}

.container-boxes{
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 50px;
  margin: 2rem 0;
  .box{
    background-color: #fff;
    padding: 15px 25px 15px 15px;
    box-shadow: 5px 5px 10px #0000001f;
    border-radius: 5px;
    height: 332px;
    .pieChart1{
      width: 250px;
      height: 250px;
      margin: 0 auto;
    }
    .content{
      border-bottom: 1px solid #CACACC;
      height: 40px;
      p{
        margin: 0;
        line-height: 40px;
        span{
          font-weight: bold;
          color: #57D4FD;
        }
      }
    }
    textarea{
      width: 100%;
      height: 80%;
      border: none;
      background-color: #fff;
      color: #000;
      font-size: 1rem;
      &::-webkit-scrollbar {
        width: 8px;     /* Tamaño del scroll en vertical */
        height: 8px;    /* Tamaño del scroll en horizontal */
      }
      &::-webkit-scrollbar-thumb {
        background: #fd7e14;
        border-radius: 4px;
        cursor: pointer;
      }
      &::-webkit-scrollbar-thumb:active {
        background-color: #999999;
      }
    }
  }
  h5{
    font-weight: bold;
    color: #FF3D57;
  }
}

.contProfile{
  width: 100%;
  margin-top: 10%;
  display: flex;
  flex-direction: column;
  .headProfile{
    background-color: #f5f5f5;
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    justify-items: center;
    gap: 8px;
    img{
      border-radius: 50%;
      margin: 15px 0 15px 15px;
    }
    .text{
      height: 65px;
      margin-top: 10%;
      a{
        font-size: 1.3rem;
        font-weight: bold;
        color: #05252D;
      }
      p{
        font-size: 1rem;
        margin: 0;
      }
    }
  }
  .bodyProfile{
    width: 100%;
    border-top: 2px solid #CACACC;
    display: flex;
    justify-content: space-evenly;
    .boxData{
      margin-top: 10px;
      p{
        margin: 0;
        text-align: center;
        color: #05252D;
        font-weight: bold;
        font-size: 1.4rem;
      }
      span{
        font-size: 1rem;
        text-align: center;
      }
    }
  }
}
.headProfileTitle{
  font-size: 1.3rem;
        font-weight: bold;
        color: #05252D;
}
.content-subtask1{
  width: 100%;
  .box{
    width: 100%;
    background-color: #fff;
    padding: 15px 25px 15px 15px;
    box-shadow: 5px 5px 10px #0000001f;
    border-radius: 5px;
    height: auto;
    .containerSubtasks{
      width: 100%;
      display: flex;
      .boxTable{
        width: 50%;
        box-shadow: 5px 5px 10px #0000001f;
        border-radius: 5px;
        h2{
          color: #05252D;
          font-size: 1.5rem;
          font-family: Piersans;
        }
        .textoDetailCol{
          margin: 0 0 0 15px !important;
        }
        .status{
          background-color: #999999;
          border: none;
          width: 80px;
          height: 35px;
          text-align: center;
          line-height: 35px;
          border-radius: 5px;
          color: #fff;
        }
        .btnComment{
          background-color: #57d4fd;
          border: none;
          width: 100px;
          height: 35px;
          text-align: center;
          line-height: 35px;
          border-radius: 5px;
          box-shadow: 5px 5px 10px #0000001f;
          cursor: pointer;
          color: #000;
          font-weight: bold;
        }
        .btnCustom{
          background-color: #56ffbe;
          border: none;
          width: 100px;
          height: 35px;
          text-align: center;
          line-height: 35px;
          border-radius: 5px;
          box-shadow: 5px 5px 10px #0000001f;
          cursor: pointer;
          color: #000;
          font-weight: bold;
        }
      }
      .boxPosts{
        width: 42%;
        margin: 0 auto;
        h2{
          color: #05252D;
          font-size: 1.5rem;
        }
        .validationSubtask{
          display: flex;
          justify-content: space-around;
          .validarSubtarea{
            width: 50%;
            p{
              text-align: center;
            }
            .ico{
              display: flex;
              justify-content: center;
              gap: 40px;
              .thumbsUP{
                font-size: 2rem;
                cursor: pointer;
              }
              .thumbsDOWN{
                font-size: 2rem;
                cursor: pointer;
              }
            }
          }
          .reatingSubtarea{
            p{
              text-align: center;
            }
            .ico{
              display: flex;
              justify-content: center;
              width: 100%;
              .css-54cjlh{
                width: auto;
              }
              .stars1{
                margin: 0 auto;
              }
            }
          }
        }
      }
    }
  }
}


.content-ModerationTask{
  width: 100%;
  margin: 2rem 0 3.5rem 0;
  .box{
    background-color: #fff;
    box-shadow: 5px 5px 10px #0000001f;
    padding: 15px;
    min-height: 150px;
    display: flex;
    p{
      color: #57D4FD;
      font-size: 1.4rem;
    }
    .contStars{
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .css-54cjlh{
        width: auto;
      }
    }
    .contBtns{
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 30px;
      .btn1{
        width: 180px;
        height: 40px;
        border: none;
        border-radius: 8px;
        background-color: #5CF993;
        box-shadow: 5px 5px 10px #0000001f;
        color: #fff;
        font-weight: bold;
        font-size: 14px;
      }
      .btn2{
        width: 180px;
        height: 40px;
        border: none;
        border-radius: 8px;
        background-color: #57D4FD;
        color: #fff;
        box-shadow: 5px 5px 10px #0000001f;
        font-weight: bold;
        font-size: 14px;
      }
    }
  }
}

.Btn12{
  background-color: transparent;
  border: none;
}

.deliverysubtaskAprobado{
  width: 100px;
  height: 30px;
  background-color: #5cf993;
  border-radius: 5px;
  text-align: center;
  color: #343a40;
  font-weight: bold;
  line-height: 30px;
}
.deliverysubtaskEn{
  width: 100px;
  height: 30px;
  background-color: #80949b;
  border-radius: 5px;
  text-align: center;
  color: #343a40;
  font-weight: bold;
  line-height: 30px;
}
.deliverysubtaskPendiente{
  width: 100px;
  height: 30px;
  background-color: #ffde59;
  border-radius: 5px;
  text-align: center;
  color: #343a40;
  font-weight: bold;
  line-height: 30px;
}
.deliverysubtaskRechazado{
  width: 100px;
  height: 30px;
  background-color: #ff5757;
  border-radius: 5px;
  text-align: center;
  color: #343a40;
  font-weight: bold;
  line-height: 30px;
}
.deliverysubtaskFinalizado{
  width: 100px;
  height: 30px;
  background-color: #57d4fd;
  border-radius: 5px;
  text-align: center;
  color: #343a40;
  font-weight: bold;
  line-height: 30px;
}


@media (max-width: 1024px){
  .contTareas{
    .Head{
      margin-top: 2rem;
      .btnBack{
        font-size: 14px;
      }
    }
    .container-boxes{
      grid-template-columns: 1fr 1fr;
      grid-template-areas: 'grid1 grid2'
                  'grid3 grid3';
      grid-gap: 10px;
      margin-top: 0;
      .box__grid{
        &:nth-child(1){
          grid-area: grid1;
        }
        &:nth-child(2){
          grid-area: grid2;
        }
        &:nth-child(3){
          grid-area: grid3;
        }
      }
    }
    .content-subtask1{
      .box{
        .containerSubtasks{
          flex-direction: column;
          .boxTable{
            width: 100%;
            margin-bottom: 2rem;
          }
          .boxPosts{
            width: 100%;
          }
        }
      }
    }
  }
}

@media (max-width: 640px){
  .contTareas{
    .Head{
      flex-direction: column;
      gap: 10px;
    }
    .container-boxes{
      display: flex !important;
      gap: 0;
      .box__grid{
        width: 100%;
        &:nth-child(3){
          .box{
            .contProfile{
              .headProfile{
                .text{
                   a{
                    font-size: 12px;
                   }
                   p{
                    display: none;
                   }
                }
              }
            }
          }
        }
      }
    }
    .content-subtask1{
      .box{
        .containerSubtasks{
          flex-direction: column;
          .boxTable{
            width: 100%;
            margin-bottom: 2rem;
          }
          .boxPosts{
            width: 100%;
          }
        }
      }
    }
    .content-ModerationTask{
      .box{
        flex-direction: column;
        align-items: center;
        .contBtns{
          flex-direction: column;
        }
      }
    }
  }
}