.content-all{
  position: relative;
  height: calc(100vh - 60px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.info{
  margin-top: 25px;
}

.row-info{
  display: flex;
}

.row-section{
  margin-top: 20px;
  width: 100%;
}

.title-text{
  margin-left: 10px;
  margin-top: 5px;
  margin-bottom: 20px;
font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-16)/var(--unnamed-line-spacing-12) var(--unnamed-font-family-poppins);
letter-spacing: var(--unnamed-character-spacing-0-4);
text-align: left;
font: normal normal bold 16px/12px PierSans;
letter-spacing: 0.4px;
color: #05252D;
opacity: 1;
}

.circle{
width: 20px;
height: 20px;
background: var(--pantone-7471-c) 0% 0% no-repeat padding-box;
background: #7ADBD4 0% 0% no-repeat padding-box;
border-radius: 50%;
opacity: 1;
}

.shadow{
  background: #00000070;
  position: absolute;
  width: 100%;
  height: calc(100vh - 60px);
  z-index: 5;
}

.form-dashboard{
  margin-top: 3vh;
  width: 90%;
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
}

.Bar-content{
  width: 100%;
}

.content-menu{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.delivery-running{
  width: 100px;
  position: relative;
height: 30px;
background: #00CA72 0% 0% no-repeat padding-box;
border-radius: 3px;
opacity: 1;
}

.delivery-tail{
  width: 100px;
  position: relative;
height: 30px;
background: #FF642E 0% 0% no-repeat padding-box;
border-radius: 3px;
opacity: 1;
}

.delivery-stop{
  width: 100px;
  position: relative;
height: 30px;
background: #FF3D57 0% 0% no-repeat padding-box;
border-radius: 3px;
opacity: 1;
}

.delivery-complete{
  width: 100px;
  position: relative;
height: 30px;
background: #3d4aff 0% 0% no-repeat padding-box;
border-radius: 3px;
opacity: 1;
}
.delivery-payment{
  width: 100px;
  position: relative;
height: 30px;
background: #c0c0c0 0% 0% no-repeat padding-box;
border-radius: 3px;
opacity: 1;
}
.delivery-credited{
  width: 100px;
  position: relative;
height: 30px;

background: #9900ff 0% 0% no-repeat padding-box;
border-radius: 3px;
opacity: 1;
}
.delivery-text{
  position:absolute;
  margin-top: 10px;
  margin-left: auto;
margin-right: auto;
left: 0;
right: 0;
text-align: center;
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 10px/var(--unnamed-line-spacing-12) var(--unnamed-font-family-poppins);
text-align: center;
font: normal normal normal 10px/12px PierSans;
letter-spacing: 0.25px;
color: #FFFFFF;
}

.dateRange{
  width: 178px;
height: 30px;
display: inline-block;
background: #EFF0F2 0% 0% no-repeat padding-box;
border-radius: 15px;
opacity: 1;
position: relative;
justify-content: space-between;
}

.source-img{
  width: 30px;
height: 30px;
opacity: 1;
}

.dateRange-text-start{
  margin-top: 10px;
  margin-left: 5px;
margin-right: auto;
left: 0;
right: 0;
text-align: center;
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 10px/var(--unnamed-line-spacing-12) var(--unnamed-font-family-poppins);
  text-align: left;
  font: normal normal normal 10px/12px PierSans;
  letter-spacing: 0.25px;
  color: #05252D;
  opacity: 1;
}

.dateRange-text-end{
  margin-top: 10px;
  margin-left: 5px;
margin-right: auto;
left: 0;
right: 0;
text-align: center;
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 10px/var(--unnamed-line-spacing-12) var(--unnamed-font-family-poppins);
  text-align: left;
  font: normal normal normal 10px/12px PierSans;
  letter-spacing: 0.25px;
  color: #05252D;
  opacity: 1;
}

.dateRange .date-start{
  width: 82px;
height: 30px;
background: #00CA72 0% 0% no-repeat padding-box;
border-radius: 15px 0px 0px 15px;
opacity: 1;
}

.select-dashboard{
  width: 20%;
  border: none;
  border-radius: 5px;
  box-shadow: 3px 3px 5px #80808080;
  padding: 10px;
  color: #292f4c;
  font-weight: bold;
}

.width{
  width: 55%;
}

.content-floatWindow{
  position: relative;
}

.compartir-content{
  position: absolute;
  z-index: 10;
  background: white;
  border-radius: 5px;
  right: 80px;
  top: 50px;
  min-width: 360px;
}

.showColls-content{
  right: 45px;
}

.showFill-content{
  right: 0;
}

.cc-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f5f5f5;
  border-radius: 5px 5px 0 0;
  padding: 10px;
}

.cople{
  display: flex;
  align-items: center;
}

.ccHeader-ico{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  width: 35px;
  background: white;
  padding: 5px;
  border-radius: 5px;
  box-shadow: 3px 3px 5px #80808080;
}

.ccHeader-text{
  margin-left: 10px;
}

.ccHeader-text p, .ccFoot-item p, .User-share p{
  margin: 0;
}

.ccHeader-titulo{
  font-weight: bold;
}

.sf-filter p{
  padding-left: 10px;
  margin: 0;
}

.sf-filter select{
  width: calc(100% - 20px);
  margin: 0 10px;
  padding: 10px;
  border: none;
  background: whitesmoke;
  border-radius: 5px;
  font-weight: 500;
  margin-bottom: 5px;
}

.sf-text{
  margin: 0 10px;
  width: calc(100% - 22px);
  border: 1px solid gray;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 5px;
}

.contentAllFilters{
  max-height: 55vh;
  overflow-y: scroll;
  margin: 5px 20px 5px 0;
}

.contentAllFilters::-webkit-scrollbar {
  width: 10px;
}

.contentAllFilters::-webkit-scrollbar-track {
  border-radius: 25px;
  background: linear-gradient(to right, white, #f5f5f5, #f5f5f5, #f5f5f5, white);
  width: 1px;
}

.contentAllFilters::-webkit-scrollbar-thumb {
  border-radius: 25px;
  background: #ff642e;
}



.User-share{
  width: 90px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
  padding-top: 15px;
}

.us-user{
  width: 50px;
  border-radius: 25px;
}

.us-apli{
  width: 50px;
  border-radius: 5px;
}

.noPad{
  padding-top: 0;
}

.us-app{
  position: absolute;
  width: 25px;
  right: 15px;
}

.ccFoot-item{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: whitesmoke;
  margin: 5px 10px;
  padding: 10px;
  cursor: pointer;
}

.ccFoot-item:first-child{
  border-radius: 10px 10px 0 0;
}

.ccFoot-item:last-child{
  border-radius: 0 0 10px 10px;
}

/* The container */
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin: 0;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: grey;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 5px;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #fff;
  border: 1px solid #80808094;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #ffffff;
  border: 1px solid #80808094;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

.container input:checked ~ .sh-textCol {
  color: #000;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 7px;
  top: 1px;
  width: 9px;
  height: 18px;
  border: solid #00ca72;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.sh-grey{
  color: grey;
}

.search-dashboard{
  border: none;
  width: 97%;
  color: #292f4c;
  font-weight: bold;
}

.search-dashboard::placeholder{
  color: #292f4c;
}

.btn-dashboard{
  box-shadow: 3px 3px 5px #80808080;
  padding: 10px;
  margin-right: 10px;
  border-radius: 5px;
  color: #02025b !important;
  background: white;
  cursor: pointer;
}

.btnOrange-dash{
  position: relative;
  z-index: 6;
  cursor: pointer;
  background: #ff642e;
  padding: 0px 12.5px;
  color: #fff !important;
  border-radius: 5px;
  font-size: 40px;
  box-shadow: 3px 3px 5px #80808080;
}

.Kanbang-content{
  width: 100%;
  overflow: hidden;
  transition: .5s;
}

.hideCards{
  height: 55px;
}

.hideLists{
  height: 65px;
}

.Kanbang-category{
  display: flex;
  align-items: center;
  margin: 15px 0;
}

.List-category{
  display: flex;
  align-items: flex-start;
  margin: 15px 0;
}

.Kanbang-category p, .List-category p{
  margin: 0;
  color: #02025b;
  font-weight: bold;
}

.Kanbang-content a{
  width: 25px;
  height: 25px;
  border-radius: 25px;
  background: #ff3d57;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  transition: .3s;
}

.Kanbang-content a:hover{
  color: white;
  cursor: pointer;
}

.rotateBtn{
  transform: rotate(-90deg);
}

.card-content{
  display: flex;
  justify-content: space-between;
}

.card{
  width: 15%;
  background: #ff3d57;
  color: white;
  font-weight: bold;
  padding: 5px;
  border-radius: 5px;
  height: fit-content;
}

.card:nth-child(2){
  background: #ff642e;
}

.card:nth-child(3){
  background: #fdab3d;
}

.card:nth-child(4){
  background: #00ca72;
}

.card:nth-child(5){
  background: #009aff;
}

.card:nth-child(6){
  background: #794fcf;
}

.card-foot a{
  background: none;
  font-size: 27px;
  border-radius: 5px;
  box-shadow: 2px 2px 3px #80808080;
}

#btn-showList{
  margin-top: 16px;
}

.table-List{
  width: 100%;
}

.table-List th, .table-List td {
  border: 15px solid #fff0;
  border-collapse: collapse;
}

.title-List{
  font-weight: 400;
  color: #02025b;
}

.grey-title{
  background: #eff0f2;
}

.centerText{
  text-align: center;
}

.firstColl{
  position: relative;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
}

.icon-container{
  background: #04ADBF;
  width: 155px;
  color: white;
  padding: 10px;
  position: absolute;
  cursor: pointer;
  left: -145px;
}

.icon-container i{
  width: 22px;
height: 15px;
}

.icon-container:hover { 
  transition: 0.3s;
  border-radius: 0 5px 5px 0;
  left: 0;
}


.fas.fa-edit {
  margin-right: 5px;
}

.edit-fc:hover { 
  transition: 0.3s;
  border-radius: 0 5px 5px 0;
  left: 0;
}

.edit-fc:hover + .name-fc { 
  transition: 0.3s;
  left: 40px; 
}


.firstColl p{
  position: relative;
  padding: 10px;
  font-weight: 400;
  margin-left: 10px;
  transition: 0.3s;
}

.msn-fc{
  color: #ff3d57;
}

.secondColl{
  text-align: center;
  border-radius: 5px;
  color: white;
  font-weight: 400;
  padding: 10px;
}

.green{
  background: #00ca72;
}

.red{
  background: #ff3d57;
}

.orange{
  background: #ff642e;
}

.thrithColl{
  text-align: center;
}

.thrithColl img{
  width: 25px;
}

.progressBar{
  display: flex;
  background: #eff0f2;
  border-radius: 25px;
  height: 30px;
  position: relative;
  overflow: hidden;
  width: 175px;
}

.progressBar p{
  margin-top: 5px;
  width: 50%;
  padding: 5px 15px;
  font-weight: 400;
  z-index: 1;
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 10px/var(--unnamed-line-spacing-12) var(--unnamed-font-family-poppins);
  color: #05252D;
opacity: 1;
font: normal normal normal 10px/12px PierSans;
}

.bar{
  height: 100%;
  position: absolute;
  background: #00ca72;
}

.hide{
  display: none;
}

.realseBtn{
  position: relative;
  z-index: 10;
}

.rbc-event{
  border-radius: 25px !important;
  background: #00ca72 !important;
}

.rbc-event-continues-after {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rbc-event-continues-prior {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rbc-event-content{
  padding-left: 5px;
}

.fa{
  font-family: 'Font Awesome 5 Free', sans-serif
}

.modal-newCamp{
  position: absolute;
  top: 18%;
  width: 90%;
  height: 64%;
  background: white;
  border-radius: 5px;
  box-shadow: 0 0 15px #808080a6;
  z-index: 5;
}

.border-mnc{
  width: 90%;
  margin: 0 5%;
}

.header-mnc{
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: grey;
  font-weight: bold;
  padding: 10px 0;
}

.header-mnc a{
  border-bottom: 5px solid #fff;
  padding: 0 10px;
}

.active-mnc{
  border-bottom: 5px solid #02025b !important;
  color: #02025b !important;
}

.btn-mnc{
  width: 50%;
  cursor: pointer;
}

.checkBox-imnc{
  width: 25%;
  display: inline;
  color: #02025b;
}

.width-imnc{
  width: 20%;
  margin: 5px 25px 20px 0;
}

.add-field{
  width: 20%;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  color: grey;
  border-bottom: 1px solid grey;
}

.foot-mnc{
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.left-fmnc{
  text-align: left;
  color: grey;
}

.rigth-fmnc{
  text-align: right;
  color: #fff;
}

.cont-btn-mnc{
  width: 50%;
}

.content-paso{
  height: 272.6px;
  width: 34%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
  margin-left: 5%;
}

.tagCont p{
  color: #fff;
}

.Content-Modal{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
  position: relative;
  width: 600%;
}

.pauta-K{
  background: white;
  color: #000;
  border-radius: 5px;
  display: flex;
  padding: 5px;
  margin-bottom: 10px;
}

.pauta-left{
  width: 70%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-end;
}

.pauta-rigth{
  width: 30%;
}

.titulo-k{
  margin-bottom: 25px;
}

.pautaTxtgrey{
  color: grey;
  font-weight: 400;
  margin: 0;
  font-size: 11px;
}

.red-k{
  width: 20px;
}

.icons-k{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.img-k{
  width: 100%;
  margin-top: 10px;
}

/*.anunciante
.container-account {
  width: 25% !important;
}

.anunciante
.container-account
.rectangle{
  width: 80%;
}*/

.anunciante
.contenedorGrid{
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-content: center;
  grid-template-areas:
  "area1 area6 area6 area6" 
  "area2 area2 area2 area2"
  "area3 area3 area3 area3"
  "area4 area4 area4 area4"
  "area5 area5 area5 area5";
  margin-bottom: 3rem;
}
.anunciante
.grid1{
  grid-area: area1;
  .cards{
    //: 74%;
    .rectangle{
      width: 90%;
      //height: 100%;
      padding-left: 20px;
    }
  }
}

.rectangle .description{
  color: #05252D !important;
}

.anunciante
.grid2{
  grid-area: area2;
}
.anunciante
.grid2
.content-section{
  justify-content: right;
  .boxCampStatus{
    width: 100%;
    .targetContent-small{
      width: 100%;
      .marginTarget{
        width: 92.5%;
      }
    }
  }
}
.anunciante
.grid3{
  grid-area: area3;
}
.anunciante
.grid4{
  grid-area: area4;
}
.anunciante
.grid6{
  grid-area: area5;
}
.anunciante
.grid7{
  grid-area: area6;
  .boxGrid7{
    width: 100%;
    .rectangle-graph{
      width: 100%;
      justify-content: space-around;
    }
  }
}




.admin
.contenedorGrid{
  width: 99%;
}
.admin
.grid2
.content-section {
  gap: 1%;
}

.admin
.grid2
.boxStatusTruers{
  width: 50%;
}
.admin
.grid2
.boxCampStatus{
  width: 50%;
}

.admin
.grid2
.boxCampStatus
.targetContent-small{
  width: 100%;
  margin: 0;
}
.admin
.grid2
.boxCampStatus
.targetContent-small
.marginTarget{
  width: 85%;
  
}

.admin 
.grid2 
.section-one-resume 
.targetContent{
  width: 100%;
}
.admin 
.grid2 
.section-one-resume 
.targetContent
.marginTarget{
  width: 85%;
}

.admin 
.grid3
.social-network
.rectangle-img{
  width: 24% !important;
}
.admin 
.grid4
.content-section{
  gap: 1%;
}
.admin 
.grid4
.content-section 
.boxGrid4{
  width: 50%;
}

.admin 
.grid4
.content-section 
.boxGrid4
.targetContent-small{
  width: 100%;
}
.admin 
.grid4
.content-section 
.boxGrid4
.targetContent-small
.marginTarget{
  width: 85%;
}
.admin 
.grid4
.content-section 
.boxGrid4
.rectangle-graph{
  background-color: #fff;
  height: 300px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.admin 
.grid4
.content-section 
.boxGrid4
.rectangle-graph
.graphGeneralStatistics{
  width: 40%;
}
/**/
.anunciante 
.grid4
.content-section{
  gap: 1%;
}
.anunciante 
.grid4
.content-section 
.boxGrid4{
  width: 50%;
}

.anunciante 
.grid4
.content-section 
.boxGrid4
.targetContent-small{
  width: 100%;
}
.anunciante 
.grid4
.content-section 
.boxGrid4
.targetContent-small
.marginTarget{
  width: 85%;
}
.anunciante 
.grid4
.content-section 
.boxGrid4
.rectangle-graph{
  background-color: #fff;
  height: 300px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.anunciante 
.grid4
.content-section 
.boxGrid4
.rectangle-graph
.graphGeneralStatistics{
  width: 40%;
} /**/

.admin .grid6 .content-section{
  flex-wrap: wrap;
  .boxGrid6{
    width: 33%;
    .rectangle-graph-meddium{
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .userByState{
        position: static;
        margin: 0;
        width: 90%;
        .chartData{
          margin-bottom: 10px;
          .pieChart{
            width: 40%;
            display: flex;
            align-items: center;
          }
          .dataLegend{
            display: flex;
            flex-direction: column;
            justify-content: center;
            .dataBox {
              margin-bottom: 5px;
              p{
                margin-bottom: 0;
                margin-top: 5px;
              }
            }
          }
        }
        .dataLabels{
          margin-top: 10px;
        }
      }
    }
    .targetContent-small{
      width: 100%;
    }
  }
}
.anunciante .grid6 .content-section{
  flex-wrap: wrap;
  .boxGrid6{
    width: 49.5%;
    .rectangle-graph-meddium{
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .userByState{
        position: static;
        margin: 0;
        width: 90%;
        .chartData{
          margin-bottom: 10px;
          .pieChart{
            width: 40%;
            display: flex;
            align-items: center;
          }
          .dataLegend{
            display: flex;
            flex-direction: column;
            justify-content: center;
            .dataBox {
              margin-bottom: 5px;
              p{
                margin-bottom: 0;
                margin-top: 5px;
              }
            }
          }
        }
        .dataLabels{
          margin-top: 10px;
        }
      }
    }
    .targetContent-small{
      width: 100%;
    }
  }
}

.admin
.grid7
.boxGrid7{
  width: 100%;
  .rectangle-graph{
    width: 100%;
    justify-content: space-around;
  }
}
.content-all.accounts-page .form-dashboard .Bar-content .content-menu .container ._buttons_container_1aspn_11 .dropdown {
  padding: 0 10px;
  height: 46px;
}

@media (max-width: 1024px){
  .content-all.accounts-page{
    width: 100%;
    margin: 0;
    .form-dashboard{
      width: 100%;
      margin: 0 0 0 70px;
      padding: 0 10px;
      .Bar-content{
        padding: 20px 0 0;
        .content-menu{
          .select-dashboard{
            display: none;
          }
          .container{
            margin: 0;
            padding: 0;
            width: 30%;
            ._buttons_container_1aspn_11{
              margin: 0;
              padding-right: 25px;
              height: 46px !important;
              .dropdown{
                padding: 0  10px;
                height: 46px;
              }
            }
          }
          .btnOrange-dash{
            i{
              font-size: 1.5rem;
            }
          }
        }
      }
      .contenedorGrid{
        .grid1{
          .cards{
            .rectangle{
              .number{
                margin-bottom: 15px;
              }
            }
          }
        }
        .grid2{
          .content-section{
            flex-wrap: wrap;
          }
          .boxStatusTruers{
            width: 100%;
          }
          .boxCampStatus{
            width: 100%;
            margin-top: 12px;
          }
        }
        .grid3{
          .social-network{
            .rectangle-img{
              .number{
                left: 30px;
              }
            }
          }
        }
        .grid4{
          .content-section{
            flex-wrap: wrap;
          }
          .boxGrid4{
            width: 100%;
            &:nth-child(2){
              margin-top: 15px;
            }
            .graphGeneralStatistics{
              width: 86%;
            }
          }
        }
        .grid5{
          .cards2{
            .rectangle{
              .number{
                margin-bottom: 15px;
              }
            }
          }
        }
        .grid6{
          .boxGrid6{
            width: 100%;
            margin-top: 5px;
            &:nth-child(2){
              margin-top: 15px;
            }
            &:nth-child(3){
              margin-top: 15px;
            }
            .userByGender{
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 20px;
              .maleWrap{
                width: 90%;
                padding: 0;
              }
              .femaleWrap{
                width: 90%;
                padding: 0;
              }
              .boxGenderSocialNetwork{
                width: 90%;
                margin: 0;
              }
            }
            .userByState{
              display: flex;
              flex-direction: column;
              align-items: center;
              .chartData{
                width: 70%;
                justify-content: center;
                .pieChart{
                  margin: 0;
                  padding: 0;
                  width: 32%;
                }
                .dataLegend{
                  margin-left: 10px;
                }
              }
              .dataLabels{
                grid-template-columns: auto auto;
                grid-template-rows: auto;
                width: 70%;
                .dataBox{
                  margin-left: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 640px){
  .content-all.accounts-page{
    width: 100%;
    margin: 0;
    .form-dashboard{
      width: 100%;
      margin: 0 0 0 70px;
      padding: 0 10px;
      .Bar-content{
        padding: 20px 0 0;
        .content-menu{
          flex-direction: column;
          .select-dashboard{
            display: none;
          }
          .container {
            flex-direction: column !important;
            gap: 10px;
            width: 100% !important;
            align-items: flex-start;
            padding: 0;
            ._buttons_container_1aspn_11{
              //margin-right: 34px;
              margin-left: 50px !important;
            }
          }
          .btnOrange-dash{
            top: -46px;
            //left: 46%;
            right: 40%;
            width: 40px;
            i{
              font-size: 1.5rem;
            }
          }
        }
      }
      .contenedorGrid{
        .grid1{
          margin-top: -20px;
          .cards{
            width: 100%;
            margin-top: 10px;
            flex-wrap: wrap;
            .rectangle{
              width: 48%;
              margin: 0 0 5px;
              .number{
                margin-bottom: 15px;
              }
            }
          }
        }
        .grid2{
          .content-section{
            flex-wrap: wrap;
          }
          .boxStatusTruers{
            width: 100%;
          }
          .boxCampStatus{
            width: 100%;
            margin-top: 12px;
          }
        }
        .grid3{
          .social-network{
            padding: 0;
            flex-wrap: wrap;
            .rectangle-img{
              margin: 0 0 5px;
              width: 48% !important;
              .number{
                left: 28%;
              }
            }
          }
        }
        .grid4{
          .content-section{
            flex-wrap: wrap;
          }
          .boxGrid4{
            width: 100%;
            &:nth-child(2){
              margin-top: 15px;
            }
            .graphGeneralStatistics{
              width: 86%;
            }
          }
        }
        .grid5{
          .cards2{
            width: 100%;
            flex-wrap: wrap;
            .rectangle{
              margin: 0 0 5px;
              width: 48%;
              .description{
                margin-top: 0;
              }
              .number{
                margin-bottom: 15px;
              }
            }
          }
        }
        .grid6{
          .boxGrid6{
            width: 100%;
            margin-top: 5px;
            &:nth-child(2){
              margin-top: 15px;
              .rectangle-graph-meddium{
                height: 360px;
              }
            }
            &:nth-child(3){
              margin-top: 15px;
            }
            .userByGender{
              display: flex;
              flex-direction: column;
              align-items: center;
              gap: 20px;
              .maleWrap{
                width: 90%;
                padding: 0;
              }
              .femaleWrap{
                width: 90%;
                padding: 0;
              }
              .boxGenderSocialNetwork{
                width: 90%;
                margin: 0;
              }
            }
            .userByState{
              .chartData{
                .pieChart{
                  margin: 0;
                  padding: 0;
                  width: 44%;
                }
                .dataLegend{
                  margin-left: 10px;
                }
              }
              .dataLabels{
                grid-template-columns: auto auto;
                grid-template-rows: auto;
                width: 250px;
                .dataBox{
                  margin-left: 0;
                }
              }
            }
          }
        }
        .grid7{
          .boxGrid7{
            .rectangle-graph{
              flex-direction: column;
              .doughnutChart{
                width: 204px;
                margin-left: 30px;
                height: 200px;
              }
              .info{
                width: 90%;
                margin: 0;
                div{
                  width: 90%;
                  margin-left: 10%;
                  .rectangle-img-info-small{
                    height: 50px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 330px){
  .grid6 {
    .boxGrid6{
      .userByState {
        .chartData {
          .dataLegend{
            .dataBox{
              p{
                font-size: 12px !important;
              }
            }
          }
        }
      }
    }
  }
  .content-all.accounts-page .form-dashboard .contenedorGrid .grid7 .boxGrid7 .rectangle-graph .doughnutChart{
    margin-left: 15px;
  }
  .title-text{
    line-height: 1.5;
  }
}