.navbarFather{
  padding-left: 0 !important;
  padding-right: 0 !important;
  .container-fluid{
    display: flex !important;
    justify-content: center !important;
    width: 100% !important;
    padding: 0;
    .container{
      width: 100% !important;
      padding: 0 !important;
      max-width: 80% !important;
      .collapse{
        width: 100%;
        padding: 0;
      }
    }
  }
}


.img-fluid{
  width: 100px;
  height: 25px;
}