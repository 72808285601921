.content-submenu-userList {
  width: 100%;
  margin: 30px 0;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-wrap: wrap;
}
.content-submenu-userList
.selectorBox {
  margin: 10px 0;
}
.section-profile {
  width: 100%;
  margin: 30px 0;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-wrap: wrap;
}
.section-profile 
.targetContent {
  height: 380px;
}
.section-profile
.row.info {
  margin-top: 20px;
}
.section-profile
.tagContent {
  max-width: 675px;
}
.content-networks{
  width: 100%;
  margin: 30px 0;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-wrap: wrap;
}
.content-networks
.row-info {
  margin-top: 20px;
}

.content-pay {
  width: 100%;
  margin: 30px 0;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-wrap: wrap;
}
.content-pay
.row-info{
  margin-top: 20px;
}

.content-actions {
  width: 100%;
  margin: 30px 0;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-wrap: wrap;
}
.content-actions
.row-info{
  margin-top: 20px;
}