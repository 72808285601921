.content-all {
    position: relative;
    height: calc(100vh - 60px);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}
.sideBar {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 99px;
    height: 100vh;
    background: #EFF0F2 0% 0% no-repeat padding-box;
    opacity: 1;
}

 

.displayMenu {
    background: var(--pantone-1495-c) 0% 0% no-repeat padding-box;
    opacity: 1;
    top: 104px;
    left: 40px;
    width: 20px;
    height: 16px;
    color: #FF9015;
    font-size: 25px;
    position: relative;
    transition: all 0.5s ease;
}

.btnColaps{
    cursor: pointer;
}

.displayMenu:hover {
    color: #7ADBD4;
}

 

.ml-auto.nav-options.navbar-nav li a i {
  color : #000;
}

.fa-columns{
    width: 18 !important;
    font-size: 17px !important;
}


.ml-auto.nav-options.navbar-nav li:last-child a {
    box-shadow: 3px 3px 10px #00000029;
    transition: all 0.5s ease;
    background: #7ADBD4;
    border-radius: 5px;
    line-height: 1;
    height: 30px;
}

 

.ml-auto.nav-options.navbar-nav li:last-child a i {
    color: #fff;
}

 

.ml-auto.nav-options.navbar-nav li:last-child a:hover {
    background: #FF9015;
}

 

.ml-auto.nav-options.navbar-nav li:nth-child(4) a {
    display: flex;
    align-items: center;
    color: #000;
}

 

.ml-auto.nav-options.navbar-nav li:nth-child(4) a a {
    font-size: 11px;
}

 

.settings-nav {
    left: 0;
    height: calc(100vh - 60px) !important;
    justify-content: space-evenly;
    text-align: center;
    padding-right: 15px !important;
    max-width: 350px;
}

 

.titleOneStepClose {
    color: #FF9015;
}

 

.settings-nav .titleOneStep {
    color: #FF9015;
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;
    padding-bottom: 10px;
    font-size: 22px;
    margin: 0 0 010px;
    border-bottom: 3px solid #ccc;
    padding-top: 0;
}

 


.settings-nav .titleOneStep p {
    font-size: 16px;
}

 

.settings-nav-itemClose a i {
    color: #7E7E80 !important;
    transition: all 0.5s ease;
}

 

.settings-nav-itemClose:hover a i {
    color: #FF9015 !important;
}

 

.settings-nav-itemClose:hover{
    background: transparent;
}

.settings-nav-item:hover {
  background: transparent;
}

 

.settings-nav-item {
  padding: 0 0 0 0px;
}

 

.settings-nav-item a{
    padding: 5px 0 5px 10%;
    display: flex;
    align-content: revert;
    flex-flow: row wrap;
    align-items: revert;
    justify-content: revert;
    text-decoration: none;
    font-size: 1.1em;
    font-weight: bold;
    color: #7e7e80;
    border-radius: 0px 20px 20px 0px;
}

 

.settings-nav-item:hover > a{
    color: #f9875f !important;
    background: #f2ddc6;
    padding: 5px 0 5px 10%;
}

 

.settings-nav-item ul {
    height: 0;
    overflow: hidden;
    padding: 0;
    margin: 0;
    transition: all 0.5s ease;
}

 

.settings-nav-item:hover ul {
    height: 190px;
    overflow-y: scroll;
    overflow-x: hidden;
    
} 

 /* width */
 .settings-nav-item ul::-webkit-scrollbar {
    width: 7px;
  }
  
  /* Track */
  .settings-nav-item ul::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  }
   
  /* Handle */
  .settings-nav-item ul::-webkit-scrollbar-thumb {
    background: #57D4FD; 
    border-radius: 10px;
  }
  
  /* Handle on hover */
  .settings-nav-item ul::-webkit-scrollbar-thumb:hover {
    background: #57D4FD; 
  }

.settings-nav-item ul li a {
    background: #eff0f2 !important;
    color: #7E7E80 !important;
    border-left: 5px solid #7E7E80;
    margin: 10px 0 10px 66px;
}

 

.settings-nav-item ul li a:hover{
    color: #57D4FD !important;
    border-color: #57D4FD;
}

/*----------------------------------------------------------------*/
.settings-nav-itemClose ul {
    display: none;
    padding-left: 0;
    position: absolute;
    box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.2);
    background: #eff0f2 !important;
    left: 70px;
    padding-right:5px;
    transition: all 0.5s ease;
    
}

.settings-nav-itemClose:hover ul {
    transition: all 1s ease;
    display: inline;
    width: 40vh;
    padding-left: 0;
    list-style-type: none !important;

    
}
.settings-nav-itemClose ul li a:hover{
    transition: all 0.5s ease;
    color: #57D4FD !important;
    border-color: #57D4FD !important;
 
}

.settings-nav-itemClose ul li a {
    transition: all 1s ease;
    padding: 5px 0 5px 10%;
    display: flex;
    align-content: revert;
    flex-flow: row wrap;
    align-items: revert;
    justify-content: revert;
    font-weight: bold;
    border-radius: 0px 20px 20px 0px;
    background: #eff0f2 !important;
    color: #7E7E80 !important;
    border-left: 5px solid #7E7E80;
    margin: 10px 0 10px 66px;
    z-index: 1;
    display:block;
}
