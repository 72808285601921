.btn-show-table{
    background: #ff3d57;
    border: none;
    border-radius: 25px;
    color: white;
    padding: 0;
    width: 27px;
    height: 27px;
    margin-top: 15px;
}

.rdt_Utils_Container {
    flex-grow: 1 !important;
    width: auto !important;
}

.btn-show-table:focus{
    outline: none;
}

._2P76T{
    display: flex;
    width: 100%;
}

.sc-jrAGrp.oVMnE.rdt_TableRow,
.sc-dlfnbm.bboORw.rdt_TableHeadRow {
    border-bottom: none;
}
.progress-table-component {
    width: 100%;
}
.sc-hKgILt.sc-eCssSg.sc-iqHYGH.jpnrBA.kryBQT.gHOQQy.rdt_TableCol,
.sc-hKgILt.sc-eCssSg.sc-iqHYGH.jpnrBA.kMXuPO.gHOQQy.rdt_TableCol:nth-child(5),
.sc-hKgILt.sc-eCssSg.sc-iqHYGH.jpnrBA.kMXuPO.gHOQQy.rdt_TableCol:nth-child(7),
.sc-hKgILt.sc-eCssSg.sc-jSgupP.bmkkvw.kryBQT.koHINl.rdt_TableCell,
.sc-hKgILt.sc-eCssSg.sc-jSgupP.bmkkvw.kMXuPO.koHINl.rdt_TableCell:nth-child(5),
.sc-hKgILt.sc-eCssSg.sc-jSgupP.bmkkvw.kMXuPO.koHINl.rdt_TableCell:nth-child(7){
    background: #eff0f2;
}

.List-category {
    flex-direction: column;

    // pagination
    > div:nth-child(2) {
        width: 100%
    }
}

._3fTZM{
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    max-width: 100% !important;
}

.form-control{
    opacity: 1 !important;
    cursor: auto !important;
    height: auto !important;
}

._1nB_u {
    overflow-x: auto;
    white-space: nowrap;
}

.rdt_Utils_DropDown_Menu {
    min-width: 290px !important;
    max-width: 290px !important;
}

a._phGqS:hover {
    color: #02025b !important;
}

.rounded.form-control{
    width: 100% !important;
    position: unset !important;
}

.form-control{
    border: none;
    box-shadow: 0px 1px 5px rgba(128, 128, 128, 0.501961);
}

.status_creado {
    background: #ff642e !important;
}

.status_revision {
    background: #fdab3d !important;
}

.status_corriendo {
    background: #00ca72 !important;
}

.status_completado {
    background: #009aff !important;
}

.status_detenido {
    background: #ff3d57 !important;
}

.status_mix_aprobado {
    background: #794fcf !important;
}

.status_implementacion {
    background: #6c9c99 !important;
}

.status_cancelado {
    background: #db3522 !important;
}