
/* Backlog de pablo dos horas */
.btn-dashboard {
    text-decoration: none !important;
    display: flex;
    position: relative;
}
.btn-csv{
    color:#000000 !important;
    text-decoration: none !important;

}
.span-csv{
    position: absolute;
    z-index: 9999;
    background-color: #e3e4e6;
    color: rgb(0, 0, 0);
    font-size: 0.8rem;
    top: 43px;
    width: 100px;
    text-align: center  ;
    display: none;
    transition: all 1s;
    opacity: 0.8;
    border-radius: 5px;
}
.btn-dashboard:hover .span-csv{
    display: block;
}