.App {
    font-family: sans-serif;
    text-align: center;
  }
  
  body {
    margin: 0px;
  }
  
  .file-status-bar{
    width: 60% !important;
  }