.App {
    font-family: sans-serif;
    text-align: center;
  }
  
  body {
    margin: 0px;
  }
  
  .btn-csv{
    display: flex;
    align-items: center;
  }
  .Bar-contentCampaign{
    padding-left: 0 !important;
  }
  .Bar-contentCampaign .content-menu{
    justify-content: left;
    flex-wrap: nowrap;
  }
  .Bar-contentCampaign ._main_container_ct0vw_1 {
    width: 100% !important;
  }


.mapCampaign .leaflet-container{
  height: 52vh !important;
}

.btnCsv{
  margin-left: 10px;
}