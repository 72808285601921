.titleRol{
  font-size: 1.4rem;
  color: #05252D;
  font-weight: bold;
}

.content-menu{
  .SelectTwoSides{
    width: 60%;
    background-color: #fff;
    box-shadow: 5px 5px 10px #0000001f;
    padding-bottom: 20px;
    
    .msts__heading{
      display: flex;
      .msts__side{
        width: 50%;
        text-align: center;
        box-shadow: 5px 5px 10px #0000001f;
        height: 40px;
        line-height: 40px;
        font-size: 1rem;
        color: #05252D;
        font-weight: bold;
        border-radius: 5px;
      } 
    }
    .msts__subheading{
      display: flex;
      margin-bottom: 10px;
      .msts__side{
        width: 50%;
        .msts__filter{
          display: flex;
          justify-content: center;
          input{
            height: 30px;
            width: 80%;
            margin-top: 10px;
            border: 1px solid #CACACC;
            box-shadow: 5px 5px 10px #0000001f;
          }
        }
      }
    }
    .msts__body{
      display: flex;
      position: relative;
      .msts__side{
        width: 50%;
        height: 180px;
        overflow-y: auto;
        &::-webkit-scrollbar {
          width: 8px;     /* Tamaño del scroll en vertical */
          height: 8px;    /* Tamaño del scroll en horizontal */
        }
        &::-webkit-scrollbar-thumb {
          background: #7ADBD4;
          border-radius: 4px;
        }
        &::-webkit-scrollbar-thumb:active {
          background-color: #999999;
        }
      
        ul{
          height: 95%;
          list-style: none;
          margin: 0;
          li{
            cursor: pointer;
            &:hover{
              background-color: #7ADBD4;
              font-weight: 400;
            }
          }
        }
      }
      .msts__side_available{
        width: 47%;
        margin-right: 3%;
        padding: 15px;
      }
      .msts__side_selected{
        width: 47%;
        margin-right: 3%;
        padding: 15px;
      }
      .msts__side_controls{
        position: absolute;
        width: 40px;
        left: calc(50% - 20px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 40px;
        button:nth-of-type(1){
          height: 25px;
          width: 100%;
          padding: 0;
          background-color: transparent;
          border: none;
          &::before{
            content: "<<";
            font-weight: bold;
            font-size: 1.5rem;
            color: #7E7E80;
          }
        }
        button:nth-of-type(2){
          height: 30px;
          width: 100%;
          background-color: transparent;
          border: none;
          padding: 0;
          &::before{
            content: ">>";
            font-weight: bold;
            font-size: 1.5rem;
            color: #7E7E80;
          }
        }
      } 
    }
  }
}

.SelectRoll{
  width: 30%;
  align-self: flex-start;
  height: 40px;
  border: 1px solid #CACACC;
  box-shadow: 5px 5px 10px #0000001f;
  border-radius: 5px;
  padding: 0 10px;
  font-size: 1rem;
  color: #05252D;
  font-weight: bold;
  cursor: pointer;
}



.grid{
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-content: space-between;
  gap: 50px;
  margin-bottom: 3rem;
  &__title{
    font-size: 1.4rem;
    color: #05252D;
    font-weight: bold;
    padding-left: 30px;
    position: relative;
    &::before{
      content: "";
      display: block;
      background: #7ADBD4 0% 0% no-repeat padding-box;
      opacity: 1;
      position: absolute;
      left: 0;
      top: 3px;
      border-radius: 50%;
      width: 20px;
      height: 20px;
    }
  }
  &__Box{
    background-color: #fff;
    padding: 30px;
    box-shadow: 5px 5px 10px #0000001f;
    border-radius: 5px;
    &-child{
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 45px;
      border-bottom: 1px solid #CACACC;
      p{
        margin: 0;
        color: #05252D;
      }
      .checkbox{
        width: 30px;
        height: 18px;
        accent-color: #7ADBD4;
        margin: 0;
      }
    }
  }

}

.msts_theme_example .msts__heading .msts__side_available{
  background-color: #57d4fd;
}

.msts_theme_example .msts__heading .msts__side_selected{
  background-color: #56ffbe;
}

.BtnPanelAdmin{
  background-color: #57d4fd;
  color: #fff;
  border: none;
  border-radius: 5px;
  width: 240px;
  height: 40px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, .25);
}