.part2 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.logoAdvertiser{
  width: 400px;
  opacity: 0.5;
}

.tabs {
  display: flex;
  margin: 10px 0 0;
  justify-content: space-around;
  list-style: none;
  cursor: hand;
}
.tabitem__title {
  cursor: hand;

}

.title-tabPane {
  margin-bottom: 20px;
}
.end-part {
  margin-top: -70px;
}

@media (max-width: 1300px){
  .end-part {
    margin-top: -20px;
  }
}