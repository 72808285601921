.selectorBox{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 5px 5px 10px #0000001F;
    border-radius: 5px;
    opacity: 1;
    width: 265px;
    height: 40px;
    display: flex;
    align-items: center;
}

.selectorBox:hover {
    background: #57D4FD;
    cursor:pointer;
}
.selectorBox:focus{
    background: #FF9015;
    cursor:pointer;
}

.selectorBox i{
    margin-left: 20px;
}

.selectorBox a{
    margin-left: 10px;
    font: normal normal bold 12px/18px PierSans;
letter-spacing: 0.3px;
color: #05252D;
opacity: 1;
}
.clickActive {
    background: #57D4FD;
}