.targetContent{
width: 742px;
height: 300px;
background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 5px 5px 10px #0000001F;
border-radius: 5px;
opacity: 1;
}


.targetContent .marginTarget{
    position: relative;
width: 693px;
height: 229px;
opacity: 1;
top:30px;
margin: auto;
}

.targetContent .marginTarget .dataBox{
height: 35px;
opacity: 1;
}

.targetContent .marginTarget .dataBox .numberValues{
    width: 45px;
    height: 25px;
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) 18px/var(--unnamed-line-spacing-12) var(--unnamed-font-family-poppins);
    text-align: left;
    font: normal normal bold 18px/12px PierSans;
    letter-spacing: 0.45px;
    color: #04ADBF;
}

.targetContent .marginTarget .dataBox a{
width: 215px;
height: 25px;
text-align: left;
letter-spacing: NaNpx;
opacity: 1;
}

.targetContent-small{
    width: 361px;
    padding-top: 30px;
    padding-bottom: 30px;
    min-height: 300px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 5px 5px 10px #0000001F;
    border-radius: 5px;
    opacity: 1;
    }
    
    
    
    .targetContent-small .marginTarget{
    width: 300px;
    opacity: 1;
    margin: auto;
    }
    
    .targetContent-small .marginTarget .dataBox{
    height: 35px;
    opacity: 1;
    }
    
    .thumbsUP:hover{
        color: #3EB489;
    
    text-shadow: 5px 5px 10px #0000001F;
    border-radius: 5px;
    opacity: 1;
    transition:all 0.3s ease;

    }
    .thumbsApproved{
        color: #3EB489;
    
    text-shadow: 5px 5px 10px #0000001F;
    border-radius: 5px;
    opacity: 1;
    transition:all 0.3s ease;

    }
    .thumbsRejected{
        color: red;
    
    text-shadow: 5px 5px 10px #0000001F;
    border-radius: 5px;
    opacity: 1;
    transition:all 0.3s ease;

    }
    .thumbsDOWN:hover{
        color: red;
    
    text-shadow: 5px 5px 10px #0000001F;
    border-radius: 5px;
    opacity: 1;
    transition:all 0.3s ease;
    }
    .targetContent-small .marginTarget .dataBox .numberValues{
        width: 45px;
        height: 25px;
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) 18px/var(--unnamed-line-spacing-12) var(--unnamed-font-family-poppins);
        text-align: left;
        font: normal normal bold 18px/12px PierSans;
        letter-spacing: 0.45px;
        color: #04ADBF;
    }
    
    .targetContent-small .marginTarget .dataBox a{
    height: 25px;
    text-align: left;
    letter-spacing: NaNpx;
    opacity: 1;
    }

    .separator{
        top: 455px;
left: 174px;
width: 500px;
height: 0px;
border: 1px solid #CACACC;
opacity: 1;
    }
.boton{
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #FFFFFF;
        height: 35px;
       font-size: 1rem;
        border-radius: 5px;
        opacity: 1; 
        transition: all 0.3s ease;
    }
   
    .boton:hover{
        
        opacity: 0.7;
        color: #FFFFFF;
        
    }
    .botonDisabled{
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #FFFFFF;
        height: 35px;
       font-size: 1rem;
        border-radius: 5px;
        opacity: 1; 
        transition: all 0.3s ease;
    }

.flex-column { 
    flex-direction: none !important;
}