.targetContent{
width: 742px;
height: 300px;
background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 5px 5px 10px #0000001F;
border-radius: 5px;
opacity: 1;
}


.targetContent .marginTarget{
    position: relative;
width: 693px;
height: 229px;
opacity: 1;
top:30px;
margin: auto;
}

.targetContent .marginTarget .dataBox{
height: 35px;
opacity: 1;
}

.targetContent .marginTarget .dataBox .numberValues{
    width: 45px;
    height: 25px;
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) 18px/var(--unnamed-line-spacing-12) var(--unnamed-font-family-poppins);
    text-align: left;
    font: normal normal bold 18px/12px PierSans;
    letter-spacing: 0.45px;
    color: #04ADBF;
}

.targetContent .marginTarget .dataBox a{
width: 215px;
height: 25px;
text-align: left;
letter-spacing: NaNpx;
opacity: 1;
}

.targetContent-small{
    width: 361px;
    padding-top: 30px;
    padding-bottom: 30px;
    min-height: 300px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 5px 5px 10px #0000001F;
    border-radius: 5px;
    opacity: 1;
    }
    
    thumb
    
    .targetContent-small .marginTarget{
    width: 300px;
    opacity: 1;
    margin: auto;
    }
    
    .targetContent-small .marginTarget .dataBox{
    height: 35px;
    opacity: 1;
    }
    
    .thumbsUP:hover{
        color: #3EB489;
    
    text-shadow: 5px 5px 10px #0000001F;
    border-radius: 5px;
    opacity: 1;
    transition:all 0.3s ease;

    }
    .thumbsApproved{
        color: #3EB489;
    
    text-shadow: 5px 5px 10px #0000001F;
    border-radius: 5px;
    opacity: 1;
    transition:all 0.3s ease;

    }
    .thumbsRejected{
        color: red;
    
    text-shadow: 5px 5px 10px #0000001F;
    border-radius: 5px;
    opacity: 1;
    transition:all 0.3s ease;

    }
    .thumbsDOWN:hover{
        color: red;
    
    text-shadow: 5px 5px 10px #0000001F;
    border-radius: 5px;
    opacity: 1;
    transition:all 0.3s ease;
    }
    .targetContent-small .marginTarget .dataBox .numberValues{
        width: 45px;
        height: 25px;
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) 18px/var(--unnamed-line-spacing-12) var(--unnamed-font-family-poppins);
        text-align: left;
        font: normal normal bold 18px/12px PierSans;
        letter-spacing: 0.45px;
        color: #04ADBF;
    }
    
    .targetContent-small .marginTarget .dataBox a{
    height: 25px;
    text-align: left;
    letter-spacing: NaNpx;
    opacity: 1;
    }

    .separator{
        top: 455px;
left: 174px;
width: 500px;
height: 0px;
border: 1px solid #CACACC;
opacity: 1;
    }

.boton{
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    height: 35px;
    background: #00CA72 0% 0% no-repeat ;
   font-size: 1rem;
    border-radius: 5px;
    opacity: 1; 
    transition: all 0.3s ease;
    }
.boton1{
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    height: 35px;
    background: #e62604 0% 0% no-repeat ;
   font-size: 1rem;
    border-radius: 5px;
    opacity: 1; 
    transition: all 0.3s ease;
    }

    .boton:hover{
        
        background: #0e9259 0% 0% no-repeat padding-box;
        color: #FFFFFF;
        
    }

    .subtask {
        width: 1135px;
        justify-content: center;
        display: flex !important;
        overflow-y: hidden;
        overflow-x: scroll;
        height: 250px;
    }

    /* width */
.subtask::-webkit-scrollbar {
    width: 4px;
    height: 6px;
  }
  
  /* Track */
  .subtask::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  }
   
  /* Handle */
  .subtask::-webkit-scrollbar-thumb {
    background: grey; 
    border-radius: 10px;
  }
  /* Handle  hover*/
  .subtask::-webkit-scrollbar-thumb:hover {
    background: #FF9015; 
    border-radius: 10px;
  }

    .row-subtask {
        width: 220px;
        height: 37px;
        padding-bottom: 3rem;
        border-bottom: 1px solid gray;
        margin-bottom: 10px;
        margin-right: 20px;
    }
    .row-subtask 
    .row {
        width: 220px;

    }
    .approbed {
        width: 15px;
    }

    .thumbsApproved {
        font-size: 20px;
    }
    .fa-thumbs-down {
        font-size: 20px;
        
    }

    .row-subtask
    .text-content
    p {
        display: inline-block;
        font-size: 12px;
        text-align: center;
        padding-left: 15px;
    }

    .row-subtask
    .text-content {
        width: 112px;
        height: 38px;
    }

    .container-rating-button {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .stars {
        display: flex;
        flex-direction: row;
        width: 270px;
    }

    .subtask
    .css-54cjlh {
        margin-left: 15px;
    }
    .subtask 
    .css-dqr9h-MuiRating-label{
        width: 25px;
        height: 30px;
    }

    .subtask
    .css-iljtu2-MuiRating-root {
        font-size: 27px !important;
    }

    .content-coment1{
        width: 205px;
        min-height: 22px;
        padding: 10px;
    }
    .content-coment1
    .coment1 {
        display: inline-block;
    }
    
    .btncomment {
        width: 100%;
        height: 30px;
        font-size: 12px;
        border-radius: 5px;
        background-color: #04ADBF;
        color: #FFFFFF;
    }
    .btncomment:hover {
        width: 100%;
        height: 30px;
        color: #000;
        font-size: 12px;
        border-radius: 5px;
        background-color: #04ADBF;
        transition: all 0.3s ease;
    }
    .btns {
        width: 200px;
        display: flex;
        justify-content: space-around;
    }