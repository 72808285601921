.content-submenu-userList {
  width: 80%;
  background-color: #F7F7F7;
  margin: 3rem 0 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  position: fixed;
  top: 12px;
  z-index: 100;
  height: 80px;
  
}
.content-submenu-userList
.selectorBox {
  margin: 0 !important;
}
.Bar-content {
  padding: 20px;
}
.section-profile {
  width: 100%;
  margin: 30px 0;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
.section-profile 
.targetContent {
  height: 380px;
}
.section-profile
.row.info {
  margin-top: 20px;
}
.section-profile
.tagContent {
  max-width: 675px;
}
.content-networks{
  width: 100%;
  margin: 30px 0;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-wrap: wrap;
}
.content-networks
.row-info {
  margin-top: 20px;
}

.content-pay {
  width: 100%;
  margin: 30px 0;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-wrap: wrap;
}
.content-pay
.row-info{
  margin-top: 20px;
}

.content-actions {
  width: 100%;
  margin: 30px 0;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-wrap: wrap;
}
.content-actions
.row-info{
  margin-top: 20px;
}

.content-submenu-userList {
  z-index: 1000;
}


.pl-btn-backuserlist{
  margin-bottom: 20px;
}


/*nuevooooo*/
.contUserData{
  margin-top: 3.5rem !important;
}
.contBtnBack .btnBackUser img{
  width: 40px;
  height: 40px;
}
.contBtnBack a{
  font-size: 1.2rem;
  font-weight: bold;
  line-height: 40px;
}

.sectionProfile{
  display: grid;
  gap: 1%;
  grid-template-columns: 1fr 1fr 1fr;
  .Box{
    &:nth-child(2){
      margin-top: 37px;
    }
    .targetContent{
      width: 100%;
      height: 375px;
      overflow-y: auto;
      padding: 10px;
      &::-webkit-scrollbar {
        width: 10px;
      }
      &::-webkit-scrollbar-track {
        border-radius: 25px;
        background: linear-gradient(to right, white, #f5f5f5, #f5f5f5, #f5f5f5, white);
        width: 1px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 25px;
        background: #57D4FD;
      }      
      .marginTarget{
        width: 100%;
      }
    }
    .MuiPaper-elevation5{
      height: 100%;
      overflow-y: auto;
    }
    .targetContent-small{
      width: 100%;
      height: calc(100% - 37px);
      .marginTarget{
        height: 100%;
        width: 90%;
        .App{
          height: 100%;
          object-fit: cover;
        }
        .leaflet-container{
          width: 100%;
          height: 34vh !important;
        }
      }
    }
  }
}

.contentNetworks{
  width: 100%;
  display: grid;
  gap: 1%;
  grid-template-columns: 1fr 1fr;
  margin-top: 30px;
  .Box{
    .targetContent-small{
      width: 100%;
      .marginTarget{
        width: 90%;
      }
    }
    .targetContent{
      width: 100%;
      .marginTarget{
        width: 90%;
      }
    }
  }
}

.contentPay{
  width: 100%;
  display: grid;
  gap: 1%;
  grid-template-columns: 1fr 1fr;
  margin-top: 30px;
  .Box{
    .targetContent-small{
      width: 100%;
      .marginTarget{
        width: 90%;
      }
    }
    .targetContent{
      width: 100%;
      .marginTarget{
        width: 90%;
      }
    }
  }
}

.contentActions{
  width: 100%;
  display: grid;
  gap: 1%;
  margin-top: 30px;
  grid-template-areas: "area1 area2 area3"
                        "area4 area4 area4";
  margin-bottom: 2rem;
  .Box{
    .targetContent-small{
      width: 100%;
      .marginTarget{
        width: 90%;
        &:nth-of-type(3){
          width: 100%;
          overflow-x: hidden !important;
        }
        .delivery-tail{
          background-color: #57D4FD;
        }
      }
    }
  }
  .Box1{
    grid-area: area1;
  }
  .Box2{
    grid-area: area2;
  }
  .Box3{
    grid-area: area3;
  }
  .Box4{
    grid-area: area4;
  }
}


@media (max-width: 1024px){
  .contUserData{
    margin-top: 0 !important;
    .content-submenu-userList{
      margin-top: 0;
      position: sticky !important;
      height: 130px;
      justify-content: space-between;
      width: 100%;
    }
    .contBtnBack{
      position: relative;
      top: 3.5rem;
    }
    .sectionProfile{
      margin-top: 4rem;
      grid-template-columns: 1fr;
    }
    .contentActions{
      grid-template-areas: 
      "area1"
      "area2"
      "area3"
      "area4";
      .Box{
        width: 100%;
      }
    }
  }
}

