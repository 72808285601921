
#app-main {
    max-width: 1024px;
    margin: 0 auto;
    padding: 20px 24px;
}

textarea {
    border-radius: 4px;
    font-size: 14px;
    padding: 4px 8px;
    margin-top: 8px;
    font-family:PierSans, sans-serif;
}

input {
    border-radius: 4px;
    font-size: 14px;
    padding: 12px 8px;
}

.app-section {
    display: flex;
    flex-direction: column;
}

.app-section:not(:first-child) {
    margin-top: 24px;
}

.btn {
    border-radius: 4px;
    color: #e4e6eb;
    font-size: 16px;
    font-weight: 600;
    padding: 12px;
    border: none;
    cursor: pointer;
}

.btn:disabled {
    background-color: #494949;
    color: #ccc;
}

.btn:disabled:hover {
    cursor: not-allowed;
}

.btn:not(:disabled):hover {
    filter: brightness(0.9);
}

.action-btn {
    background-color: #2d88ff;
    margin-top: 8px;
}
.instagram-btn {
    background-color: #ffa42d;
    margin-top: 8px;
}

