/* MENU*/
.scan-menu {
  text-decoration: none;
  font-size: 1.2rem;
  width: 30%;
  margin-top: 0;
  padding: 0;
  list-style: none;
  align-self: center;

}
.scan-menu
li {
  margin-top: 25px;
  text-decoration: none;
  text-align: center;
}
.btn-plus-form {
  text-decoration: none;
  color: white;
  background: #ff642e;
  box-shadow: 1px 1px 5px #80808091;
  border-radius: 5px;
  font-weight: bold;
  font-size: 20px;
  width: 100%;
  text-align: center;
  margin: 10px 30% 0 30%;
  padding: 5px;
  margin-top: 20px;
}

/* ASOCIAR */
.content-all {
  width: 40%;
}