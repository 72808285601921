.modalFather{
  height: 58% !important;
  width: 55% !important;
  p{
    margin: 0 28px;
  }
  .bodyModalComment{
    height: 200px !important;
    textarea{
      width: 300px;
      font-size: 1rem;
    }
  }
}

@media (max-width: 1024px){
  .modalFather{
    width: 90% !important;
  }
}