.userByState{
    position: absolute;
    margin-top: 10px;
}
.chartData
{
    display: flex;
}
.pieChart{
    width: 60%;
    margin-left: 40px;

}

.dataLegend{
    margin-left: 30px;
    width: 50%;
    margin-top: 5px;
}

.dataLegend .dataBox{
    font: normal normal normal 11px/11px PoppiPierSansns;
    letter-spacing: 0.4px;
    color: #05252D;
    opacity: 1;
    line-height: 10px;   /* within paragraph */
  margin-bottom: 10px; /* between paragraphs */
  display: flex;
  align-items: center;
}
.dataLegend .dataBox p{
    margin-bottom: 0;
}

.dataLabels{
    margin-top: -10px;
    display: grid;
    grid-template-columns: auto auto;
}

.dataLabels .dataBox{
    margin-left: 20px;
    letter-spacing: var(--unnamed-character-spacing-0-3);
    text-align: left;
    font: normal normal normal 10px/10px PierSans;
    letter-spacing: 0.3px;
    width: 120px;
    color: #05252D;
    opacity: 1;
    line-height: 1.2;   /* within paragraph */
    margin-bottom: 10px;
  
}

.dataLabels .dataBox::before{
    content: "";
    display: block;
    position: absolute;
    margin-left: -20px;
    margin-top: -5px;
     /* width: 15px;
    height: 15px;
   /* background: #7ADBD4; */
    border-radius: 50%;
    opacity: 1;
}

.dataLegend .dataBox::before{
    content: "";
    display: block;
    position: absolute;
    margin-left: -20px;
    margin-top: -3px;
     /* width: 15px;
    height: 15px;
   /* background: #7ADBD4;*/
    border-radius: 50%;
    opacity: 1;
}
.circle {
    width:10px;
}
.color1 {
    color: #FF3D57;
    float: left;
    width:10px;
    font-size: "xx-small"  !important;
  }
.color2 {
    color: #FDAB3D;
    float: left;
    width:10px;
    font-size: "xx-small" !important;
  }
.color3 {
    color: #00CA72;
    float: left;
    width:10px;
    font-size: "xx-small" !important;
  }
.color4 {
    color: #009AFF;
    float: left;
    width:10px;
    font-size: "xx-small" !important;
  }
.color5 {
    color: #794FCF;  
    float: left;
    width:10px;
    font-size: "xx-small"  !important;
  }
  .dataLegend .dataBox p {

    font: normal normal normal PierSans !important;

    line-height: 1 !important;   /* within paragraph */

}